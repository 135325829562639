import React, {useEffect, useState, useCallback, forwardRef} from 'react';
import { View, TextInput, StyleSheet } from 'react-native';
import Colors from '../constants/Colors';
import _, { throttle, debounce } from 'lodash';

// we're passing down a reference to TextInput
// Since this is a functional component, we're passing down a ref
const DelayedTextInput = forwardRef((props, ref) => {
	
	const [inputText, setInputText] = useState("");

	useEffect(() => {
		setInputText(props.text);
	}, [props.text]);

	// debouncing textInput Call
	const submitInput = (text) => props.onDelayedSubmit(text);
	const debouncedSubmit = useCallback(debounce(submitInput, 500), []);

	const handleInputChange = (text) => {
		setInputText(text);
		debouncedSubmit(text);
	}

	return (
		<View style={styles.filtering_content}>
			<TextInput
				style={{fontFamily: 'noticia', height:40, width:'80%', marginHorizontal:20, padding:6, borderRadius: 8, borderColor:Colors.primaryColor, borderWidth:StyleSheet.hairlineWidth}}
				placeholder="Your query"
				placeholderTextColor={Colors.accentWeakColor}
				onChangeText={handleInputChange}
				value={inputText}
				ref={ref}
			/>
		</View>
	);
});

const styles = StyleSheet.create({
	filtering_content: {
		width:'100%',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},	
})

export default DelayedTextInput;