import React from 'react';
import { Animated, View, ScrollView, Text, StyleSheet, Pressable } from 'react-native';

import IChingWilhelm from '../iching-wilhelm-dataset/data/iching_wilhelm_translation';
import Genekeys from '../genekeys-dataset/genekeys';

import Colors from '../constants/Colors.js';
import Elements from '../constants/elements';

import DefaultText from '../components/DefaultText';
import FocusAwareStatusBar from './FocusAwareStatusBar';
import { useCardAnimation } from '@react-navigation/stack';

import TreeOfLife from '../components/SVG/TreeOfLife';
import AstroWheelSVG from '../components/SVG/AstroWheelSVG';

const get_element_style = (element) => {
	return {
		color: Elements.colors[Elements.from_symbol[element]],
		fontSize: 30
	};
}

const IChingInfoScreen = ({route, navigation}) =>{

	// pass hexagram in params (navigation), we do the rest
	const { hexagram } = route.params;
	const { current } = useCardAnimation();

	// extract IChing Information
	const {
		hex_font, 
		english,
		binary,
		wilhelm_above, // alchemical, chinese, symbolic
		wilhelm_below, // alchemical, chinese, symbolic 
		wilhelm_image, // text, comment
		wilhelm_judgment, // text, comment
		wilhelm_lines, // 1-6, each text & comment
		wilhelm_symbolic
	} = IChingWilhelm[hexagram];

	// "keyword":"From Entropy To Syntropy", "shadow": "Entropy", "gift":"Freshness", "siddhi":"Beauty", "dilemma":"Numbness", "repressive": "Depressive", "reactive":"Frenetic", "codon":"The Ring of Fire", "AA":"Lysine"

	// extract Genekey Information
	const {
		keyword, dilemma,
		repressive, reactive,
		shadow, gift, siddhi,
		codon, AA
	} = Genekeys.find(x => x.hex === parseInt(hexagram));

	// console.log(iching_wilhelm);
	// console.log(shadow, gift, siddhi);

	// const binary = IChing.find(x => x.hex === hexagram.hexagram).binary;
  	const element_1 = Elements.from_binary[binary.toString().substring(0,2)];
  	const element_2 = Elements.from_binary[binary.toString().substring(2,4)];
  	const element_3 = Elements.from_binary[binary.toString().substring(4,6)];

	const bielement_to_treeoflife = {
		"🜁": { // Y
			"🜁": "1",
			"🜂": "3",
			"🜄": "2",
			"🜃": [4,5,6],
		},
		"🜂": { // H
			"🜁": "6",
			"🜂": "5",
			"🜄": "4",
			"🜃": [7,8,9],
		},
		"🜄": { // V
			"🜁": "9",
			"🜂": "8",
			"🜄": "7",
			"🜃": ['air', 'fire', 'water', 'earth'],
		},
		"🜃": { // H
			"🜁": "air",
			"🜂": "fire",
			"🜄": "water",
			"🜃": "earth",
		},
	}

	const selected_saphira = [
		Elements.bielement_to_treeoflife[element_3][element_2],
		Elements.bielement_to_treeoflife[element_2][element_1]
	];
	// console.log("selected_saphira", selected_saphira);

	
	// StatusBar.setBarStyle('light-content');
	return (

		<View style={{flex:1, alignItems: 'center', justifyContent: 'center'}}>

			<Pressable
				style={[
				StyleSheet.absoluteFill,
				{ backgroundColor: 'rgba(0, 0, 0, 0.5)' },
				]}
				onPress={navigation.goBack}
			/>

			<Animated.View style={{
				width: '80%', height:'80%', overflow:'hidden', borderRadius: 30,
				transform: [
					{
					scale: current.progress.interpolate({
						inputRange: [0, 1],
						outputRange: [0.75, 1],
						extrapolate: 'clamp',
					}),
					},
				],}}
			>

				<ScrollView style={styles.screen} contentContainerStyle={styles.screen_content}>

					<FocusAwareStatusBar barStyle="light-content"/>

					{/* Overview Section */}
					<View style={styles.section}>
						
						<Text style={styles.header_text}>Hexagram {hexagram}</Text>
						{/* <Text style={styles.header_text}>{english}</Text> */}

						<View style={styles.elemental_interpretation}>
							<View style={{width: 125, justifyContent: 'center'}}>
								<TreeOfLife height={60} radius_sephira={3} selected={selected_saphira.flat()} pathways={selected_saphira}/>
							</View>

							<View>
							<View style={{width: 220, height: '100%', justifyContent: 'center', alignItems: 'center'}}>
								<AstroWheelSVG width={220} hexagram={hexagram} color_mode={'light'} middle_circle={false}/>
							</View>
							<View style={{width: 220, height:'100%', justifyContent: 'center', alignItems: 'center', position: 'absolute', zIndex: 20}}>
								<Text style={styles.hex_font}>{hex_font}</Text>
							</View>
							</View>

							<View style={styles.triple_elemental}>
								<Text style={get_element_style(element_1)}>{element_1}</Text>
								<Text style={get_element_style(element_2)}>{element_2}</Text>
								<Text style={get_element_style(element_3)}>{element_3}</Text>
							</View>

						</View>

					</View>

					{/* Genekeys Section */}
					<View style={styles.section}>
						<Text style={styles.above_text}>Gene Key</Text>
						<Text style={styles.header_text}>{keyword}</Text>

						<Text style={styles.above_text}>Dilemma</Text>
						<Text style={styles.genekey_subheader_text}>{dilemma}</Text>

						<Text style={styles.above_text}>Path</Text>
						<Text style={styles.genekey_subheader_text}><Text style={{color:'red'}}>{shadow}</Text> <Text style={{color:'lightgreen'}}>{gift}</Text> <Text style={{color:'lightblue'}}>{siddhi}</Text></Text>

						{/* <DefaultText>{shadow} -- {gift} -> {siddhi}</DefaultText> */}
						{/* <DefaultText>{gift}</DefaultText> */}
						{/* <DefaultText>{siddhi}</DefaultText> */}

						<Text style={styles.above_text}>Codon Ring</Text>
						<Text style={styles.genekey_subheader_text}>{codon}</Text>
						{/* <DefaultText>{AA}</DefaultText> */}
					</View>

					{/* Wilhelm Section */}
					<View style={styles.section}>
						<View style={styles.wilhelm_section}>
							<Text style={styles.above_text}>Wilhelm Translation</Text>
							<Text style={styles.header_text}>{english}</Text>
							{/* <DefaultText style={styles.header_text}>Wilhelm Translation</DefaultText> */}

							<Text style={styles.subheader_text}>The Judgement</Text>
							<Text style={styles.iching_text}>{wilhelm_judgment.text}</Text>
							<Text style={styles.iching_comments}>{wilhelm_judgment.comments}</Text>

							<Text style={styles.subheader_text}>The Image</Text>
							<Text style={styles.iching_text}>{wilhelm_image.text}</Text>
							<Text style={styles.iching_comments}>{wilhelm_image.comments}</Text>

							<Text style={styles.subheader_text}>Visual Interptation</Text>
							<Text style={styles.iching_comments}>{wilhelm_symbolic}</Text>
						</View>
					</View>

				</ScrollView>

			</Animated.View>
		</View>
	);
}

const styles = StyleSheet.create({
	screen: {
		flex: 1,
		backgroundColor: Colors.primaryWeakColor,
	},

	section: {
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: 5,
		paddingVertical: 20,
		backgroundColor: Colors.primaryColor,
		width: '100%'
	},	

	wilhelm_section: {
		justifyContent: 'center',
		alignItems: 'center',
		marginHorizontal:'10%'
	},

	elemental_interpretation: {
		width: '100%',
		height: 125,
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		flexWrap: "wrap",
	},

	triple_elemental: {
		flexDirection: 'column',
		width: 125,
		justifyContent: 'center', alignItems: 'center'
	},

	hex_font: {
		lineHeight: 25,
		fontSize: 25,
		// top:3,
		color: Colors.accentColor,
		// position: 'absolute',
		// justifyContent: 'center',
		// alignItems: 'center',

		// height: '100%',
		// width: '100%'
		// includeFontPadding: false
	},

	screen_content: {
		// padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
	},

	header_text: {
		color: Colors.accentColor,
		fontSize: 30,
		fontFamily: 'prompt-regular'
	},

	above_text: {
		color: Colors.accentColor,
		fontSize: 12,
		fontFamily: 'noticia',
		marginTop: 8
	},

	subheader_text: {
		color: Colors.accentColor,
		fontSize: 20,
		fontFamily: 'prompt-regular',
		marginTop: 15
	},

	subheader2_text: {
		color: Colors.accentColor,
		fontSize: 15,
		fontFamily: 'noticia'
	},

	genekey_subheader_text: {
		color: Colors.accentColor,
		fontSize: 20,
		fontFamily: 'prompt-regular',
	},

	iching_text: {
		color: Colors.accentColor,
		fontSize: 17,
		fontFamily: 'garamond-bold',

		textAlign: 'center',

		marginTop: 10,
	},

	iching_comments: {
		color: Colors.accentColor,
		fontSize: 15,
		fontFamily: 'noticia',

		// textAlign: 'left',

		marginTop: 10,
	},

});

export default IChingInfoScreen;