import React, {useState, useEffect}  from 'react';
import { View, Text, StyleSheet, Dimensions, Animated, Easing } from 'react-native';

import Svg, { Circle, Path, G, Line, Text as TextSVG } from 'react-native-svg';

const color_mid_light = "#ccc";
const color_faint_light = "#aaa";
const color_light = "#eee";

import Colors from '../constants/Colors';

const LineWheelSVG = (props) => {

	// essential information
	const { width } = props;

	const { line_fract, colo_fract, tone_fract } = props.ephemeris;

	// center
	const c = [width/2, width/2];

	// radius
	const r = width/2;

	// ratio parameters to determine the height / size of the circle!
	const outer_circle_ratio = 0.9;
	const dail_segment_ratio = 0.75;
	const line_ratio = 0.5;
	const color_ratio = 0.7;
	const tone_ratio = 1;

	const draw_segments = () => {

		const pieces = 6;
		const segment_arc = (Math.PI*2.0)/pieces;
		const seg_rad = (iter) => (segment_arc * iter);
		const x_rot = (iter, r) => Math.sin(seg_rad(iter)) * r;
		const y_rot = (iter, r) => Math.cos(seg_rad(iter)) * r;

		return(
			<G>
				{
					[...Array(pieces).keys()].map( i => {
						return <Line
							x1={c[0] + x_rot(i, r*outer_circle_ratio)}
							y1={c[1] + y_rot(i, r*outer_circle_ratio)}
							x2={c[0] + x_rot(i, r*dail_segment_ratio)}
							y2={c[1] + y_rot(i, r*dail_segment_ratio)}
							stroke={Colors.accentColor}
							strokeWidth={0.5}
							key={`line_seg_${i}`}
						/>
					})
				}
			</G>
		);
	}

	const draw_dail = (fraction, ratio) => {
		return <Line
			x1={c[0]}
			y1={c[1]}
			x2={c[0] + to_rad_cos(fraction, r*ratio)}
			y2={c[1] + to_rad_sin(fraction, r*ratio)}
			stroke={color_light}
			strokeWidth={1}
		/>
	}

	const draw_circles = () => {
		return <G key="outer circles!">
			<Circle
				cx={c[0]}
				cy={c[1]}
				r={r*outer_circle_ratio}
				stroke={Colors.accentColor}
				fill={'#0000'}
				strokeWidth={1}
				key={`circle_${r}`}
			/>
			<Circle
				cx={c[0]}
				cy={c[1]}
				r={1}
				stroke={Colors.accentColor}
				fill={Colors.accentColor}
				strokeWidth={1}
				key={`circle_center_${r}`}
			/>
		</G>
	}

	const to_rad_cos = (fraction, r) => {
		// given a fraction (eg: 60% of the circle), and a r, calculates the X position. When added to a center, you get a to-from
		const rad = (fraction-0.25)*Math.PI*2.0; // 0.25 offset to make 12 o clock start
		return Math.cos(rad)*r;
	}

	const to_rad_sin = (fraction, r) => {
		// given a fraction (eg: 60% of the circle), and a r, calculates the Y position. When added to a center, you get a to-from
		const rad = (fraction-0.25)*Math.PI*2.0; // 0.25 offset to make 12 o clock start
		return Math.sin(rad)*r;
	}

	return <Svg height={width} height={width} viewBox={`0 0 ${width} ${width}`} style={{width:width, height:width, ...styles.container}}>
		{/* Draw Outer Circles */}
		{draw_circles()}

		{/* Draw Dials for [lines, colros, tones] */}
		{draw_dail(line_fract, line_ratio)}
		{draw_dail(colo_fract, color_ratio)}
		{draw_dail(tone_fract, tone_ratio)}

		{/* Draw small little ticks on the outside */}
		{draw_segments()}

	</Svg>

}

const styles = StyleSheet.create({
	container: {
		justifyContent: 'center',
		alignItems: 'center',
	},
});

export default LineWheelSVG;