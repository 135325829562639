import { Platform } from 'react-native';

export const disableRubberband = f => {

	// Only on web
	let isWeb = Platform.OS === 'web' ? true : false;
	if (isWeb){
		// Inject style
		const style = document.createElement('style')
		style.textContent = `body { position:fixed }`
		return document.head.append(style)
	}
}