export default {
	primaryColor: '#222',
	primaryWeakColor: '#444',
	primaryLightColor: '#999',
	accentColor: '#eee',
	accentWeakColor: '#6b6b6b',

	// astrowheel
	colorFaintLight: "#aaa",
	colorMidLight: "#ccc",
	colorLight: "#eee"
}