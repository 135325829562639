import React, {useState, useEffect, useRef} from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Platform, StatusBar, Dimensions, Animated, FlatList} from 'react-native';
import * as Font from 'expo-font';
// import { AppLoading, Localization } from 'expo';
import * as Location from 'expo-location';
import AsyncStorage from '@react-native-async-storage/async-storage';

import * as Linking from 'expo-linking';

// redux
// import { useSelector } from  'react-redux';
import axios from 'axios' // web calls

// Components
// import PlanetInfo from '../components/PlanetInfo';
import HeaderWidget from '../components/HeaderWidget';
// import MainButton from '../components/MainButton';
// import AstroWheel from '../components/SVG/AstroWheel';
import AstroWheelSVG from '../components/SVG/AstroWheelSVG';

class SvgOmitter extends React.Component {
	render() {
	  const { collapsable, ...props } = this.props;
	  return <AstroWheelSVG {...props} />;
	}
}

const AnimatedAstroWheelSVG = Animated.createAnimatedComponent(SvgOmitter);

// Helpers
import { getEphemeris } from '../helpers/astrology';


import FocusAwareStatusBar from './FocusAwareStatusBar';
import Colors from '../constants/Colors';
import { SafeAreaView, useSafeAreaInsets} from 'react-native-safe-area-context';

import PlanetList from '../components/PlanetList';
import { min } from 'lodash';


const WidgetScreen = (props) => {

	//// React Hook States

	const scrollY = useRef(new Animated.Value(0)).current;

	useEffect(() => {
		scrollY.addListener(({value}) => getNewEphemeris(value));
	}
	,[scrollY]);

	// Is location granted?
	const [grantedLocation, setGrantedLocation] = useState(false);
  
	// Show more planets ? (if a sacrifice has been given)
	const [showPlanets, setShowPlanets] = useState(true);
  
	// Weather
	const [weather, setWeather] = useState('not_loaded');
	const [lastWeatherPullDate, setLastWeatherPullDate] = useState(0);
  
	// Weather Location
	const [weatherLocation, setWeatherLocation] = useState("Hoeselt,Belgium");
	const [location, setLocation] = useState(-1);
	const [enterWeatherLocation, setEnterWeatherLocation] = useState(false);
	
	// user data
	const [userData, setUserData] = useState({}); // user data store
	const [fetchedUserData, setFetchedUserData] = useState(false); // first time load
  
	// Ephemeris
	const [ephemeris, setEphemeris] = useState('empty');
  
	// Show Compass
	const [compassOn, setCompassOn] = useState(false);

	// Astrowheel Size
	const [wheelSize, setWheelSize] = useState(100);

	// Custom Date Display
	const [customDateEnabled, setCustomDateEnabled] = useState(true); // Debug

	const [customDate, setCustomDate] = useState(new Date());
  
	const onChangeCustomDate = (date) => {
		setCustomDate(date);
		// console.log(date);
		// getNewEphemeris();
	}

	const fetchWeather = async () => {
	  const now = new Date();
	  if ( (now - lastWeatherPullDate) < 1000*60*5){ // < 5 minutes
		console.log((now-lastWeatherPullDate),"<",1000*60*5);
		console.log("skipping weather pull, have one from < 5m ago:", lastWeatherPullDate);
		return;
	  }
  
		try {
			const result = await axios(`https://api.openweathermap.org/data/2.5/weather?q=${weatherLocation}&appid=6290b8bb4b1ffe047333d6d9edbf10b2`,);
		setWeather(result.data);
  
		// store date to limit weather pull to 1 every 5 minutes
		const d = new Date();
		setLastWeatherPullDate(d);
		storeDateWeatherPull(d);
		  } catch (error) {
			// Error retrieving data
		  }
	};
  
	const restoreUserData = (user_json) => {
		  if (user_json == undefined)
			  return;
  
	  const user = JSON.parse(user_json);
	  // console.log("obj",user);
	  setUserData(user);
  
	  if (user.weatherLocation !== undefined)
		setWeatherLocation(user.weatherLocation);
	}
	
	const storeDateWeatherPull = async (date) => {
		  try {
				await AsyncStorage.setItem(
					'weather_pull_date', JSON.stringify(date.toString())
				);
		  } catch (error) {
				console.log("storeWeatherDateError:", error);
		  }
	};
	
	const storeLocation = async (location) => {
		  try {
				await AsyncStorage.setItem(
					'location', JSON.stringify(location)
				);
		  } catch (error) {
				console.log("storeLocation", error);
		  }
	  };
  
	const _retrieveUserData = async () => {
		  try {
			const value = await AsyncStorage.getItem('user');
			if (value !== null) {
				restoreUserData(value);
		}
		
		const value_2 = await AsyncStorage.getItem('last_weather_pull');
			if (value_2 !== null) {
		  		const lastWeatherPull = Date.parse(value_2);
				console.log("Last weather pull from storage:", lastWeatherPull);
		  		setLastWeatherPullDate(lastWeatherPull);
			}
		} catch (error) {
			// Error retrieving data
		}
	};
	
	// calculate epheremis
	const getNewEphemeris = (const_extra=0) => {

		if (const_extra!=0) {
			let new_date = new Date();
			new_date.setHours(new_date.getHours()+const_extra/10.0);
			// console.log(new_date);
			// console.log(parseInt(const_extra));
			setEphemeris( getEphemeris(new_date, showPlanets, location) );
			setCustomDate(new_date);
		}
		else{
			let new_date = new Date();
			setEphemeris( getEphemeris(new_date, showPlanets, location) );

			// console.log(customDate);
		}
			

		//   setEphemeris( getEphemeris(Moment("2020-06-07").tz("UTC"), showPlanets, location) );
		//   console.log("location", location);
	}
  
	useEffect(()=>{
		getNewEphemeris();
	},[scrollY]);

	const getLocationData = async () => {
	  let { status } = await Location.requestPermissionsAsync();
  
	  if (status !== 'granted') {
		// setErrorMsg('Permission to access location was denied');
	  } else {
		let location = await Location.getCurrentPositionAsync({});
		setLocation(location);
		// console.log(location);
		// storeLocation(location);
  
		return location;
	  }
	}
  
	useEffect(() => { 
		// if first load, fetch user data, and then set user location....
		// if (!fetchedUserData){
		// 	_retrieveUserData().then(()=> {
		// 	// request location permission
		// 	fetchWeather();
		// 	setFetchedUserData(true);
		// 	});
			
		// } else {
		// 	fetchWeather();
		// }
		
		// // unless we already have one stored!
		// getLocationData();
	
		// // calculate new ephemeris
		// getNewEphemeris(); 
  
	}, [customDate, customDateEnabled]);
  
	const toggleShowPlanets = () => {
	  setShowPlanets(!showPlanets);
	};
  
	const refreshHandler = () => {
	  getNewEphemeris();
	  fetchWeather(weatherLocation);
	};
  
	const handleSetLocation = () => {
	  // toggle setWeatherLocation Input Prompt
	  setEnterWeatherLocation(!enterWeatherLocation);
	}
  
	const handleToggleCompass = () => {
	  setCompassOn(!compassOn);
	}
  
	let barStyle = 'dark-content';
	if (Platform.OS == 'android') barStyle = 'light-content'
  
	if(ephemeris == 'empty') return (
		<View></View>
	)
	
	const handleOpenGnomonApp = () => {
		const url = 'https://gnomon.adamblvck.com';
		if(Platform.OS == 'web')
			window.open(url, '_blank');
		else
			Linking.openURL(url);
	};

	const navigateToHexagram = (hex) => {
		console.log("hmmm", hex);

		props.navigation.navigate('IChing',{
			hexagram: hex
		});
	};

	const onWheelLayout = (event) => {
		let { x, y, width, height } = event.nativeEvent.layout;
		console.log(Math.min(width, height));
		console.log(width, height);
		setWheelSize(Math.min(width, height));
	}

	const render_vertical_view = () => {
		return <View style={styles.content}>
			<PlanetList
				handleNavigate={navigateToHexagram}
				data={[	
					ephemeris.sun, ephemeris.moon
				]}
			/>

			<View style={styles.wheel} onLayout={onWheelLayout} >
				<AstroWheelSVG
					// time={scrollY.interpolate({
					// 	inputRange: [0, 100],
					// 	outputRange: [0,2*3.1415],//['rgba(0,0,0,0.0)', Color.HEADER_COLOR],
					// 	extrapolate: 'extend',
					// 	useNativeDriver: true,
					// })}
					width={wheelSize}
					hexagram_object={ephemeris}
					color_mode={'light'}
					planet_mode={true}
				/>
			</View>
		</View>
	}

	const render_wide_view = () => {
		return <View style={styles.wide_content}>
			<PlanetList
				handleNavigate={navigateToHexagram}
				data={[	
					ephemeris.sun 
				]}
				vertical={true}
				style={{flex:1}}
				widget={true}
			/>

			<View style={{...styles.wheel, flex:3, height: '100%', justifyContent: 'center', alignItems: 'center'}} onLayout={onWheelLayout} >
				
				<AstroWheelSVG
					// time={scrollY.interpolate({
					// 	inputRange: [0, 350],
					// 	outputRange: [0,8*3.1415],//['rgba(0,0,0,0.0)', Color.HEADER_COLOR],
					// 	extrapolate: 'extend',
					// 	useNativeDriver: true,
					// })}
					width={wheelSize}
					hexagram_object={{ sun: ephemeris.sun, earth: ephemeris.earth, moon: ephemeris.moon,}}
					color_mode={'light'}
					planet_mode={true}
					// scrollEventThrottle={16}
				/>
			</View>
			<PlanetList
				handleNavigate={navigateToHexagram}
				data={[	
					ephemeris.moon
				]}
				vertical={true}
				style={{flex:1}}
				widget={true}
			/>

			<View style={{position: 'absolute', bottom:4, right:4}}>
				<TouchableOpacity onPress={handleOpenGnomonApp}>
				<Text style={{fontFamily:'noticia', fontSize:'10px', textDecorationLine: 'underline', color:Colors.primaryLightColor}}>Gnomon</Text>
				</TouchableOpacity>
			</View>

		</View>
	}

	const data_test = [...Array(10*10*10).keys()];

	const toLetter = {
		'0':'a',
		'1':'b',
		'2':'c',
		'3':'d',
		'4':'e',
		'5':'f',
		'6':'f',
		'7':'e',
		'8':'d',
		'9':'c',
		'10':'b',
	}

	return (
		<View style={styles.container}>
			<SafeAreaView style={{ flex: 0, backgroundColor: Colors.accentColor }} />
			<FocusAwareStatusBar barStyle="light-content" backgroundColor={Colors.accentColor}/>
				<HeaderWidget
					style={styles.header}
					userData={userData}
					weatherLocation={weatherLocation}
					weather={weather}
					openSetLocation={handleSetLocation}

					// setCustomDateEnabled={setCustomDateEnabled}
					onChangeCustomDate={onChangeCustomDate}
					customDate={customDate}

					widget={true}
				/>

				{/* Conditional rendering of spaces */}
				{render_wide_view()}

				{/* <View style={{height:50, marginVertical:-15, align: 'center', justifyContent: 'center'}}>
					<Animated.FlatList
						horizontal
						showsHorizontalScrollIndicator={false}
						data={data_test}
						renderItem={({item, index, seperators})=>
							<View style={{height:30, paddingVertical:10, align: 'center', justifyContent: 'center'}}>
								<View style={{marginHorizontal:10, height:10+(index%5==0)*10, width:StyleSheet.hairlineWidth, backgroundColor:Colors.accentWeakColor+`${toLetter[index%5]}${toLetter[index%5]}`}}></View>
							</View>
						}
						
						style={{backgroundColor:Colors.primaryColor, zIndex:10}}

						scrollEventThrottle={32}
						onScroll={
							Animated.event([
								{
									nativeEvent: {
										contentOffset: {
											x: scrollY
										}
									}
								}
							],
							{ 
								useNativeDriver: true
							})
						}

					/>
				</View> */}
	
		</View>
	);
  }
  
  WidgetScreen.navigationOptions = {
	style :{
		borderTopColor: "red",
	}
  };

  const styles = StyleSheet.create({

	swiper_wrapper: {
		height: 50
	},

	container: {
		flex: 1,
		backgroundColor: '#eee',
		// flexDirection:'column'
	},
  
	header: {
		flex: 0.2
	},

	content: {
		flex: 1,
		flexDirection: 'column',
		backgroundColor: '#222',
	
		borderTopLeftRadius: 40,
		borderTopRightRadius: 40,
	
		// paddingTop: 15,
		...Platform.select({
				ios: {
			paddingTop: Dimensions.get('window').width < 400 ? 10 : 0,
				},
				android: {
					paddingTop: 10,
				},
			}),
	},

	wide_content: {
		flex: 1,
		flexDirection: 'row',
		backgroundColor: '#222',

		alignItems: 'center',
		justifyContent: 'space-between',

		borderTopLeftRadius: 25,
		borderTopRightRadius: 25,
	},
  
	planetary_container: {
	//   alignItems: 'center',
	//   justifyContent: 'space-around',
		// flex: 1,
		// width:'100%',
		// height:'100%',
	  	flexDirection: 'row',
	},
  
	wheel: {
		flex:1,
		// paddingBottom: 40,
		...Platform.select({
			ios: {
				marginBottom: Dimensions.get('window').width < 400 ? -20 : 0
			},
			android: {
				marginBottom: -10
			},
		}),
		alignItems: 'center',
		justifyContent: 'space-around',
	},
  
	mainbutton: {
	  width: 60,  
	  height: 60,   
	  borderRadius: 30,            
	  backgroundColor: 'white',                                    
	  // position: 'absolute',                                          
	  // bottom: 10,                                                    
	  // right: 10,
	},
  
  });

  export default WidgetScreen;