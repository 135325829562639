import React, {useState, useEffect, useRef, Fragment} from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, Dimensions } from 'react-native';
import { EvilIcons } from '@expo/vector-icons'; 
import AsyncStorage from '@react-native-async-storage/async-storage';

// import { DatePicker } from 'react-native-wheel-datepicker';

import moment from 'moment-timezone';

const capitalize = (str, lower = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase()); ;


	
// custom react hook to update the clock every second
const useInterval = (callback, delay) => {
	const savedCallback = useRef();
	
	// Remember the latest function.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);
	
	// Set up the interval.
	useEffect(() => {
		function tick() {
		savedCallback.current();
		}
		if (delay !== null) {
		let id = setInterval(tick, delay);
		return () => clearInterval(id);
		}
	}, [delay]);
};

const HeaderWidget = props => {

	const {customDate} = props;
	const widget = props.widget ?? false;

	const [timezone, setTimezone] = useState(7200); // Belgium Summer Time
	const [time, setTime] = useState( moment(customDate).tz("UTC").add(timezone, 'second') );
	const [weatherInfo, setWeatherInfo] = useState({});

	const restoreUserData = (userData) => {
		if (userData == undefined)
			return;		

		// restore timezone
		setTimezone(userData.timezone);
	}

	const storeUser = async (user) => {
		try {
		  	await AsyncStorage.setItem(
				'user', JSON.stringify(user)
		  	);
		} catch (error) {
		  	console.log("storeUserError:", error);
		}
	};

	useEffect(() => {
		
		// restore parameters from userData
		restoreUserData(props.userData);

		// have fresh weather_thing
		if (props.weather !== 'not_loaded' && props.weather.cod==200) {

			const { temp, temp_min, temp_max, pressure, humidity } = props.weather.main;
			const description = capitalize(props.weather.weather[0].description);

			const weather_info = {
				temp: Math.round(temp - 273.15),
				temp_min: Math.round(temp_min - 273.15),
				temp_max: Math.round(temp_max - 273.15),
				pressure: Math.round(pressure),
				humidity: Math.round(humidity),

				description: description,

				// # getter javascript example #selfreferencing
				get descr() {
					return `${this.temp}°C - ${this.description}`// - ${this.pressure} hPa`
				}
			};

			// set weather
			setWeatherInfo(weather_info);

			// set timezone
			const { timezone, name } = props.weather;
			setTimezone(timezone);

			storeUser({
				timezone: timezone,
				weatherLocation: props.weatherLocation, // this is assumed to be present cus we have weather!
				weather_info: weather_info,
			});
		}

	}, [props])

	// update time every second
	useInterval(() => {
		// Your custom logic here
		const t = moment(customDate)//.tz("UTC").add(timezone, 'second')
		setTime( t );
	}, 100);

	const handleOpenLocationInput = () => {
		props.openSetLocation(true);
	}

	return (
		<View style={{...styles.container, height: widget ? '50px' : styles.container.height}}>
			<View style={{width: Dimensions.get('window').width, height: '100%'}}>

				{/* Main Info */}
				<View style={styles.date_weather_container}>
					<Text style={styles.title}>{time.format('H:mm')}</Text>
					<Text style={styles.header}>{time.format('dddd')}</Text>
					<Text style={styles.header}>{time.format('Do MMMM YYYY')}</Text>
				</View>	
				
			</View>

      	</View>
	);
};

const styles = StyleSheet.create({

	container: {
		// flex: 1,
		width: '100%',
		height: '25%',
		justifyContent: 'space-around',
		alignItems: 'center',
		backgroundColor: '#eee',
	},

	date_weather_container: {
		flexDirection: 'row',
		justifyContent: 'space-around',
		alignItems: 'center',
		flex: 1,
		marginBottom: 7,
		// width: '100%',
	},

	location_container: {
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
		flex: 1,
	},

	title: {
		marginBottom: 2,
		fontSize: 40,
		fontFamily: 'noticia',
		color: '#222',
		textAlign: 'center',
	},

	header: {
		marginBottom: 1,
		fontSize: 20,
		fontFamily: 'noticia',
		color: '#222',
		textAlign: 'center',
	},

	header_seconds: {
		marginBottom: 2,
		fontSize: 22,
		fontFamily: 'noticia',
		color: '#222'
	},

	weather_location: {
		fontSize: 12,
		fontFamily: 'noticia',
		color: '#222'

	}
});

export default HeaderWidget;

