import React, {useState, useEffect}  from 'react';
import { View, Text, StyleSheet, Dimensions, Animated, Easing } from 'react-native';

import PureCanvas from '../canvas/PureCanvas';

const color_mid_light = "#ccc";
const color_faint_light = "#aaa";
const color_light = "#eee";

const draw_line = (ctx, xa,ya , xb,yb, color, width = 1) => {
	ctx.beginPath(); // new path
	ctx.lineWidth = width;
	ctx.strokeStyle = color;
	ctx.moveTo(xa, ya);
	ctx.lineTo(xb, yb);
	ctx.closePath();
	ctx.stroke(); // Draw it
}


class LineWheel extends React.Component {

	constructor(props) {
		super(props);
		this.saveContext = this.saveContext.bind(this);

		this.state = {
		};
	}

	draw_linewheel(ctx) {
		// clear it all
		ctx.clearRect(0, 0, this.width, this.height );

		// make center the center
		ctx.translate(this.width/2.0, this.height/2.0);

    	// ctx.restore()

		// WRITE SYMBOLS ON ASTRAL CHART
		ctx.textBaseline = 'middle';
		ctx.textAlign = "center";

		// Draw Wheel
		// draw_wheel(ctx, {x:0,y:0}, planets, color_faint_light);

		const {line_fract, colo_fract, tone_fract} = this.props.ephemeris;

		// console.log('line', line_fract);
		// console.log('color', colo_fract);
		// console.log('tone', tone_fract);

		const to_rad_cos = (fraction, r) => {
			const rad = (fraction-0.25)*Math.PI*2.0; // 0.25 offset to make 12 o clock start
			return Math.cos(rad)*r;
		}

		const to_rad_sin = (fraction, r) => {
			const rad = (fraction-0.25)*Math.PI*2.0; // 0.25 offset to make 12 o clock start
			return Math.sin(rad)*r;
		}

		const r = 15;

		// draw_line(ctx, 0, 0, to_rad_cos(0, r)    , to_rad_sin(0, r), '#f00', 1);

		draw_line(ctx, 0, 0, to_rad_cos(line_fract, r*0.5), to_rad_sin(line_fract, r*0.5),     color_mid_light, 1);
		draw_line(ctx, 0, 0, to_rad_cos(colo_fract, r*0.8), to_rad_sin(colo_fract, r*0.8), color_mid_light, 1);
		draw_line(ctx, 0, 0, to_rad_cos(tone_fract, r), to_rad_sin(tone_fract, r), color_mid_light, 1);

		// circle around line clock
		ctx.beginPath();
		ctx.arc(0, 0, r*0.9, 0, 2*Math.PI, false);
		ctx.closePath();
		ctx.stroke(); // Draw it

		// draw small white dot in middle
		ctx.beginPath();
		ctx.arc(0, 0, 2, 0, 2*Math.PI, false);
		ctx.closePath();
		ctx.lineWidth = 0;
		ctx.fillStyle = color_mid_light;
		ctx.fill();

		ctx.translate(-this.width/2, -this.height/2);
	}

	componentDidMount() {
		const ctx = this.ctx;
		this.draw_linewheel(ctx);
	}

	componentDidUpdate(prevProps) {
		// if ephemeris changed, update ephemeris
		// if (this.props.ephemeris !== undefined && this.props.ephemeris.date !== prevProps.ephemeris.date) {
			const ctx = this.ctx;
			this.draw_linewheel(ctx);
			// this.resetSiderealTime();
		// }
	}

	saveContext(ctx) {
		this.ctx = ctx;
		this.width = this.ctx.canvas.width;
		this.height = this.ctx.canvas.height;
	}

	render() {
		const width = 30;

		return <View style={styles.container}>
			<PureCanvas width={width} height={width} contextRef={this.saveContext} />
		</View>;
	}

}

const styles = StyleSheet.create({
	container: {
		width: 30,
		height: 30,
		justifyContent: 'center',
		alignItems: 'center',
	},
});

export default LineWheel;