import * as React from 'react';
import { View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';

import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
// import { createDrawerNavigator } from '@react-navigation/drawer';

import Colors from '../constants/Colors.js';
import { Ionicons } from '@expo/vector-icons';
import TimeScreen from '../screens/TimeScreen';
import WidgetScreen from '../screens/WidgetScreen';

import IChingInfoScreen from '../screens/IChingInfoScreen';
import TransitScreen from '../screens/TransitScreen.js';
import LookupScreen from '../screens/LookupScreen.js';
import ProfilesScreen from '../screens/profiles/ProfilesScreen.js';
import ProfileListScreen from '../screens/profiles/ProfileListScreen.js';
import AboutScreen from '../screens/AboutScreen.js';

const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

const TimeStackNavigator = () => {
	return <Stack.Navigator
		screenOptions={({ route }) => ({
			headerShown: route.name == 'Now' ? false : true ,

			headerStyle: {
				backgroundColor: Colors.primaryColor,
			},
			headerTintColor: Colors.accentColor,
			headerTitleStyle: {
				fontWeight: 'bold',
				fontFamily: 'prompt-regular'
			},
		})}
	>
		<Stack.Screen name="Now" component={TimeScreen}
			options={({ route }) => ({
				title: `Gnomon - Time`,
			})}
		/>
		<Stack.Screen
			name="IChing"
			component={IChingInfoScreen}
			options={({ route }) => ({
				title: `Gnomon - Hexagram ${route.params.hexagram}`,
				presentation: 'transparentModal',
				headerShown: false,
				cardOverlayEnabled: true
			})}
		/>
	</Stack.Navigator>
};

const LookupStackNavigator = () => {
	return <Stack.Navigator
		screenOptions={({ route }) => ({
			headerShown: route.name == 'Lookup' ? false : true ,

			headerStyle: {
				backgroundColor: Colors.primaryColor,
			},
			headerTintColor: Colors.accentColor,
			headerTitleStyle: {
				fontWeight: 'bold',
				fontFamily: 'prompt-regular'
			},
		})}
	>
		<Stack.Screen name="Lookup" component={LookupScreen}
			options={({ route }) => ({
				title: `Gnomon - Lookup`,
			})}
		/>
		<Stack.Screen
			name="IChingLookup"
			component={IChingInfoScreen}
			options={({ route }) => ({
				title: `Gnomon - Hexagram ${route.params.hexagram}`,
				presentation: 'transparentModal',
				headerShown: false,
				cardOverlayEnabled: true
			})}
		/>
	</Stack.Navigator>
};

const MainAppNavigatorStack = () => <Tab.Navigator
	initialRouteName={'Time'}
	screenOptions={({ route }) => ({
		headerShown: false,
		tabBarIcon: ({ focused, color, size }) => {
			let iconName;
			switch (route.name) {
				case 'Time':
					iconName = 'time'
					break;
				case 'Transit':
					iconName = 'ios-eye'
					break;
				case 'LookupTabs':
					iconName = 'ios-search'
					break;
				case 'Profiles':
					iconName = 'ios-person'
					break;
				case 'About':
					iconName = 'information'
					break;
			}

			// You can return any component that you like here!
			return <Ionicons name={iconName} size={size} color={color} iconStyle={{margin:0}} />
			
		},

		tabBarActiveTintColor:Colors.accentColor,
		tabBarInactiveTintColor:Colors.accentWeakColor,
		tabBarActiveBackgroundColor: Colors.primaryColor,
		tabBarInactiveBackgroundColor: Colors.primaryColor,

		tabBarIconStyle: {
			marginTop: 0
		},

		tabBarStyle: {
			backgroundColor: Colors.primaryColor,

			// Remove border top on both android & ios
			borderTopWidth: 0,
			borderTopColor: "transparent",
			elevation: 0,
			shadowColor : '#5bc4ff',
			shadowOpacity: 0,
			shadowOffset: {
				height: 0,
			},
			shadowRadius: 0,
		}

	})}
	>
	<Tab.Screen name="Time" component={TimeStackNavigator} />
	<Tab.Screen name="LookupTabs" component={LookupStackNavigator} options={({ route }) => ({
					title: 'Lookup',
				})}/>
	{/* <Tab.Screen name="Profiles" component={ProfilesScreen} /> */}
	<Tab.Screen name="About" component={AboutScreen}
		options={({ route }) => ({
			title: `Gnomon - About`,
			tabBarLabel: 'About'
		})}
	/>
</Tab.Navigator>;

const linking = {

	prefixes: ['https://*.adamblvck.com', 'https://adamblvck.com', 'gnomon://'],
	config: {
		screens: {
			// Widget App
			WidgetApp: 'embed',

			// Main App
			Home: {
				path: '',
				screens: {

					// Time Tab config
					Time: {
						path: '',
						screens: {
							Now:{
								path: '',
							},
							IChing: {
								path: 'iching',
							}
						}
					},

					// Time Tab config
					LookupTabs: {
						path: 'lookup',
						screens: {
							Lookup:{
								path: '',
							},
							IChingLookup: {
								path: 'iching',
							}
						}
					},

					// About Tab config
					About: {
						path: 'about',
					},
				}
			}
		}
	},
};

const GnomonNavigator = () => {
	return (
		<NavigationContainer linking={linking}>
			<Stack.Navigator
				screenOptions={({ route }) => ({
					headerShown: false,
				})}
			>
				<Stack.Screen name="Home" component={MainAppNavigatorStack} />
				<Stack.Screen name="WidgetApp" component={WidgetScreen} />
			</Stack.Navigator>				

			
		</NavigationContainer>
	);
};

export default GnomonNavigator;