import React from 'react';
import { View, StyleSheet } from 'react-native';
import Svg, { Circle, Path, Text as TextSVG, G, Line, Defs, LinearGradient, Stop} from 'react-native-svg';
import _ from 'lodash';
import Colors from '../../constants/Colors';

const HolographicChart = (props) => {

	// Component Usage
	// <TreeOfLife height={height} radius_sephira={radius_sephira} selected={['kether', 'earth']}/>
	//
	// Specify in `selected` which sephira you want to select in your UI
	// Options:
	// Main Sephirat: 

	const { height, radius_spheres, profile } = props;

	const selected = [];

	// Third of circle radius
	const r_3 = height / 3 / 2;

	const hexagram_angle = 2*Math.PI/6.0;
	const side_pillar_x_offset = Math.sin(hexagram_angle)*r_3;
	const side_pillar_y_offset = r_3 - Math.cos(hexagram_angle)*r_3;

	const color_activation = '#50D638';
	const color_venus = '#D43F56';
	const color_pearl = '#5383EB';
	const color_pearl_itself = '#FADB55';

	const HD_GK_MAPPER = {
		'p_earth': 'LIFESWORK',
		'p_sun': 'EVOLUTION',
		'd_earth': 'RADIANCE',
		'd_sun': 'PURPOSE',

		'd_moon': 'ATTRACTION',

		'p_venus': 'IQ',
		'p_mars': 'EQ',
		'd_venus': 'SQ',

		'd_mars': 'CORE',
		'p_jupiter': 'PEARL',
		'd_jupiter': 'CULTURE',
	};

	const GK_HD_MAPPER = {
		'LIFESWORK':'p_sun',
		'EVOLUTION': 'p_earth',
		'RADIANCE': 'd_sun',
		'PURPOSE': 'd_earth',

		'ATTRACTION': 'd_moon',

		'IQ': 'p_venus',
		'EQ': 'p_mars',
		'SQ': 'd_venus',

		'CORE': 'd_mars',
		'PEARL': 'p_jupiter',
		'CULTURE': 'd_jupiter',
	};

	const pathways = [
		['LIFESWORK','EVOLUTION'],
		['EVOLUTION','RADIANCE'],
		['RADIANCE','PURPOSE'],
		['PURPOSE','ATTRACTION'],

		['ATTRACTION','IQ'],

		['IQ','EQ'],
		['EQ','SQ'],
		['SQ','CORE'],

		['CORE','CULTURE'],
		['CULTURE','LIFESWORK'],
		['LIFESWORK','CORE'],

		['CORE','PEARL'],
		['CULTURE','PEARL'],
		['LIFESWORK','PEARL'],
	]

	const spheres = {

		'LIFESWORK': {
			cx: height/2,
			cy: r_3,
			fill_color: color_activation
		},
		'EVOLUTION': {
			cx: height-r_3,
			cy: height/2,
			fill_color: color_activation
		},
		'RADIANCE': {
			cx: r_3,
			cy: height/2,
			fill_color: color_activation
		},
		'PURPOSE': {
			cx: height/2,
			cy: height-r_3,
			fill_color: 'url(#purpose)'
		},

		'ATTRACTION': {
			cx: height/2,
			cy: height-r_3*2,
			fill_color: color_venus
		},
		'IQ': {
			cx: height/2 - side_pillar_x_offset,
			cy: r_3*3 + side_pillar_y_offset,
			fill_color: color_venus
		},
		'EQ': {
			cx: height/2 + side_pillar_x_offset,
			cy: r_3*3 + side_pillar_y_offset,
			fill_color: color_venus
		},
		'SQ': {
			cx: height/2,
			cy: height/2,
			fill_color: color_venus
		},

		'CORE': {
			cx: height/2 - side_pillar_x_offset,
			cy: r_3*2 + side_pillar_y_offset,
			fill_color: 'url(#core)'
		},
		'CULTURE': {
			cx: height/2 + side_pillar_x_offset,
			cy: r_3*2 + side_pillar_y_offset,
			fill_color: color_pearl
		},
		'PEARL': {
			cx: height/2,
			cy: r_3*2,
			fill_color: color_pearl_itself
		},
	};

	const malkuth_elements = {
		air: {
			start_angle: -Math.PI/4 - Math.PI/2, 
			end_angle: Math.PI/4 - Math.PI/2,
			color: '#FFFF66',
		},
		fire: {
			start_angle: -Math.PI/4 + Math.PI, 
			end_angle: Math.PI/4 + Math.PI,
			color: '#CC6633',
		},
		water: {
			start_angle: -Math.PI/4, 
			end_angle: Math.PI/4,
			color: '#669900',
		},
		earth: {
			start_angle: -Math.PI/4 + Math.PI/2,
			end_angle: Math.PI/4 + Math.PI/2,
			color: '#000000',
		},
	}

	const getCoordinatesForRadius = (center, angle, radius) => {
		const x = center[0] + Math.cos(angle)*radius;
		const y = center[1] + Math.sin(angle)*radius;
		return [x, y];
	}

	const draw_slice = (center, angle_start, angle_stop, radius, color) => {
		// given angle_start & angle_stop, draw pie chart around center, with radius and color specified
		const [startX, startY] = getCoordinatesForRadius(center, angle_start, radius);
		const [endX, endY]     = getCoordinatesForRadius(center, angle_stop, radius);
		const largeArcFlag = Math.abs(angle_stop - angle_start) > Math.PI ? 1 : 0;
		const pathData = [
			`M ${startX} ${startY}`, // Move
			`A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
			`L ${center[0]} ${center[1]}`, // Line
		].join(' ');

		return <Path d={pathData} fill={color} key={pathData} />;
	}

	return (
		<Svg height="100%" width="100%" viewBox={`0 0 ${height} ${height}`}>

			<Defs>
				<LinearGradient id="core" x1="0" y1="1" x2="1" y2="0">
					<Stop offset="0.2" stopColor={color_venus} stopOpacity="1" />
					<Stop offset="0.8" stopColor={color_pearl} stopOpacity="1" />
				</LinearGradient>

				<LinearGradient id="purpose" x1="0" y1="1" x2="1" y2="0">
					<Stop offset="0.2" stopColor={color_activation} stopOpacity="1" />
					<Stop offset="0.8" stopColor={color_venus} stopOpacity="1" />
				</LinearGradient>
			</Defs>

			{/* Draw Main Pathways */}
			{_.map(pathways, (vv, ii) => {
				// unpack
				const [from, to] = vv;

				let dasharray = '0,0';
				if ( (from=='EVOLUTION' && to=='RADIANCE') || (to == 'PEARL'))
					dasharray = '5,5';

				// draw line
				return (
					<Line
						x1={spheres[from].cx}
						y1={spheres[from].cy}
						x2={spheres[to].cx}
						y2={spheres[to].cy}
						stroke={Colors.primaryLightColor}
						strokeWidth="1"
						key={`L_${from}_${to}`}
						strokeDasharray={dasharray}
					/>
				)
			})}

			{/* Draw Main Spheres */}
			{_.map(spheres, (s, name) => {
				const stroke = Colors.primaryColor;

				const {hexagram, hexagram_line} = profile[GK_HD_MAPPER[name]] ?? {hexagram:0, hexagram_line:0};
				console.log(name, hexagram, hexagram_line);

				return (
					<G key={`G_${name}`}>
						<Circle
							cx={s.cx}
							cy={s.cy}
							r={radius_spheres}
							stroke={s.fill_color}
							strokeWidth="0.5"
							fill={s.fill_color}
							key={`S_${name}`}
						/>
						
						<TextSVG
							x={s.cx}
							y={s.cy}
							fill={Colors.primaryColor}
							textAnchor="middle"
							dominantBaseline="middle"
							fontSize={15}
							fontFamily="noticia"
							key={`T_${name}`}
						>{`${hexagram}.${hexagram_line}`}</TextSVG>

					</G>
				)
			})}

			

			{/* Draw Malkuth Slices */}
			{/* {_.map(malkuth_elements, (m, name) => {
				const selected_element = selected.some(e => e == name);
				if (!selected_element) return;
				return (
					draw_slice([sephirot[10].cx, sephirot[10].cy], m.start_angle, m.end_angle, radius_spheres, m.color)
				)
			})} */}

		</Svg>
	)
}

const styles = StyleSheet.create({
	screen: {

	}
});

export default HolographicChart