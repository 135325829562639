import React, {useState, useEffect}  from 'react';
import { View, Text, StyleSheet, Dimensions, Animated, Easing, Platform } from 'react-native';
import { Magnetometer } from 'expo-sensors';

// import moment from 'moment-timezone';
import lstjs from 'local-sidereal-time';
import * as Location from 'expo-location';
import AstroWheelSVG from './AstroWheelSVG';

class CompassAstroWheelSVG extends React.Component {

	constructor(props) {
		super(props);
		this.saveContext = this.saveContext.bind(this);

		// For debugging purposes
		// const date = moment().tz("UTC").toDate(); 
		// const lst_hours = lstjs.getLST(date, 4.46)
		// const lst_string = lstjs.lstString(date, 4.46);
		// console.log("new", lst_hours, lst_string);

		// const gps_lon = this.props.location != -1 ? this.props.location : 0;

		this.wheelSize = props.wheelSize;
		this.ephemeris = props.ephemeris;

		this.state = {
			angle: 0,
			prev_angle: 0,
			subscription: null,
			spinAnim: new Animated.Value(0),
			magn_north: new Animated.Value(0),
			magnetic_north: 0,
			sidereal_time: this.local_sidereal_time()
		};

		
		Magnetometer.setUpdateInterval(250);

		this._subscribe = this._subscribe.bind(this);
		this._unsubscribe = this._unsubscribe.bind(this);
		this.resetSiderealTime = this.resetSiderealTime.bind(this);
	}

	local_sidereal_time = () => {
		const date = new Date(); 
		const {location} = this.props;
		const gps_lon = location != -1 ? location.coords.longitude : 0;

		// console.log("gps_lon",gps_lon);
		return lstjs.getLST(date, gps_lon);
	}

	resetSiderealTime = () => {
		const local_sidereal = this.local_sidereal_time();
		// console.log("local_sidereal", local_sidereal);
		this.setState({sidereal_time: local_sidereal});
	}

	_toggle = () => {
		if (this.state.subscription) {
		  	this._unsubscribe();
		} else {
		  	this._subscribe();
		}
	};

	componentDidMount () {
		
	}

	componentWillUnmount () {
		this._unsubscribe();
	}

	_angle = (magnetometer) => {
		let angle = 0;
		if (magnetometer) {
			let { x, y, z } = magnetometer;

			if (Math.atan2(y, x) >= 0) {
				angle = Math.atan2(y, x) // * (180 / Math.PI);
			}
			else {
			  angle = (Math.atan2(y, x) + 2 * Math.PI) // * (180 / Math.PI);
			}
		}

		return angle // * 180 / Math.PI);
	};

	_subscribe = () => {

		const normalize_angle = (angle) => {
			let newAngle = angle;
    		while (newAngle <= 0) newAngle += Math.PI*2;
    		while (newAngle > Math.PI*2) newAngle -= Math.PI*2;
    		return newAngle;
		}

		// make a new subscription which essentially polls data
		this.setState({

			// let location = await Location.getHeadingAsync({});
			// Location.watchHeadingAsync

			subscription: Location.watchHeadingAsync((result) => {
				const magnetic_north = result.trueHeading / 180.0 * Math.PI;
				const sidereal_offset = this.state.sidereal_time*15.0 / 180.0 * Math.PI;
				// console.log(sidereal_offset);
				const a =  2*Math.PI - normalize_angle(magnetic_north-sidereal_offset+Math.PI/2) ; // + (this.state.sidereal_time*15) );//Math.PI*2.0 - this._angle(result)
				const magnetic_north_js = 2*Math.PI - magnetic_north;
				const prev = this.state.prev_angle;

				// set new angle inbetween new angle, as a lowpass function
				this.setState( {angle: a, prev_angle: prev, magnetic_north: magnetic_north_js} );
				this.animateToNewAngle(a);
				// props.setHeading(Math.atan2(result.y, result.x) * (180 / Math.PI));
			})

		});
			
		// this._unsubscribe();
	};

	_unsubscribe = () => {
		try{
			this.state.subscription && this.state.subscription.remove();
			this.setState({subscription:null});
		}

		catch (error) {
			console.log('error -', error);
		}
	};

	saveContext(ctx) {
		this.ctx = ctx;
		this.width = this.ctx.canvas.width;
		this.height = this.ctx.canvas.height;
	}

	componentDidUpdate(prevProps) {
		// if ephemeris changed, update ephemeris
		if (this.props.compassEnabled !== undefined && this.props.hexagram_object.date !== prevProps.hexagram_object.date) {
			// const ctx = this.ctx;
			// this.draw_astrowheel(ctx);
			this.resetSiderealTime();
		}

		if (this.props.compassEnabled == true && prevProps.compassEnabled == false)
		{
			console.log('subscribing');
			this._subscribe();
		}
		else if (this.props.compassEnabled == false && prevProps.compassEnabled == true)
		{
			console.log('unsubscribe');
			this._unsubscribe();
		}
	}

	animateToNewAngle(angle) {
		Animated.timing(
			this.state.spinAnim,
		  {
			toValue: 1,
			duration: 500,
			easing: Easing.inOut(Easing.ease),
			useNativeDriver: true
		  }
		).start();
	}

	componentDidMount() {
		// const ctx = this.ctx;
		// this.draw_astrowheel(ctx);
		this._subscribe();
	}

	// getting accurate headingn through sensor fusion via -> https://github.com/psiphi75/ahrs

	render() {

		// const width = Dimensions.get('window').width*RATIO_TO_SCREEN;

		// true if previous angle is LARGER than next angle
		const prev_a_gr = this.state.prev_angle > this.state.angle ? true : false

		const a = prev_a_gr ? this.state.prev_angle : this.state.angle;
		const b = !prev_a_gr ? this.state.angle : this.state.prev_angle;

		const spin = this.state.spinAnim.interpolate({
			inputRange: [0, 1],
			outputRange: [`${a}rad`, `${b}rad`]
		});

		const magn_north = this.state.spinAnim.interpolate({
			inputRange: [0, 1],
			outputRange: [`${a}rad`, `${b}rad`]
		});
	
		// const spin_north = this.state.spinAnim.interpolate({
		// 	inputRange: [0, 1],
		// 	outputRange: [`${a-this.state.sidereal_offset}rad`, `${b-this.state.sidereal_offset}rad`]
		// });

		const rotation = this.props.compassEnabled ? spin : 0;
		// const rotation_north = this.props.compassEnabled ? spin_north : 0;

		const magnnorth = `-${this.state.magnetic_north}rad`;
		// console.log("magnnorth: ", magnnorth);
		// console.log("magnetic_north: ", this.state.magnetic_north);

		

		const { width, hexagram_object, color_mode, planet_mode } = this.props;

		console.log(rotation, width, "<----");

		return <View style={[styles.container, {width:this.props.width, height:this.props.width}]}>
			{/* <LocationTest/> */}
			<Animated.View style={[styles.animated_view, {transform: [{rotate: rotation}] }]}>
				{/* <View style={{...styles.north_arrow, transform: [{rotate: '0rad'}] }}>
					<Text style={{color:'white'}}>WOW</Text>
				</View> */}
				<AstroWheelSVG
					// time={scrollY.interpolate({
					// 	inputRange: [0, 100],
					// 	outputRange: [0,2*3.1415],//['rgba(0,0,0,0.0)', Color.HEADER_COLOR],
					// 	extrapolate: 'extend',
					// 	useNativeDriver: true,
					// })}
					
					width={width}
					hexagram_object={hexagram_object}
					color_mode={color_mode}
					planet_mode={planet_mode}
				/>
			</Animated.View>
		</View>;
	}
};

const styles = StyleSheet.create({
	container: {
		flex:1,
		justifyContent: 'center',
		alignItems: 'center',

		marginTop: 0, // to center drawings at 100 (- 10 natural margin)
	},

	animated_view: {
		justifyContent: 'center',
		alignItems: 'center',
		color:'white'
	},

	north_arrow: {
		position: "absolute",
		justifyContent: 'center',
		alignItems: 'center',

		width: 50,
		height: 50,
	}
});

// center of wheel
const c = {
	x: 0,
	y: 0,
};

export default CompassAstroWheelSVG;