import React, { useState, useEffect, useCallback, useRef, createRef } from 'react';
import { Component, View, Text, StyleSheet, Dimensions, Platform, FlatList, Animated, TouchableOpacity } from 'react-native';
import Svg, { Circle, Rect, Path } from 'react-native-svg';
import _, { filter, throttle } from 'lodash';

import Colors from '../constants/Colors';
import Elements from '../constants/elements';
import IChingHexagrams from '../iching-wilhelm-dataset/data/i-ching-basic';
import GenekeysIndex from '../genekeys-dataset/genekeys-normalized';

import FocusAwareStatusBar from './FocusAwareStatusBar';
import { SafeAreaView } from 'react-native-safe-area-context';
import { FontAwesome5 } from '@expo/vector-icons'; 

import MainButton from '../components/MainButton';
import AstroWheelSVG from '../components/SVG/AstroWheelSVG';
import HexagramShortInfo from '../components/HexagramShortInfo';

import SegmentedControl from '@react-native-segmented-control/segmented-control';
import DelayedTextInput from '../components/DelayedTextInput';

import UniversalIcon from '../components/UniversalIcon';

// class SvgOmitter extends React.Component {
// 	render() {
// 	  const { collapsable, ...props } = this.props;
// 	  return <AstroWheelSVG {...props} />;
// 	}
// }

// const AnimatedAstroWheelSVG = Animated.createAnimatedComponent(SvgOmitter);

const FourElementRenderRow = (props) => {
	return (
		<View style={{flexDirection: 'row'}}>
			{ // render item for the four elements
				[...Array(4).keys()].map(i => {
					const element_symbol = Elements.element_array[i];
					const element_bigram = Elements.digram[element_symbol];
					const active_element_color = Elements.colors[Elements.from_symbol[element_symbol]]

					// color in activated elements
					const color = i == props.state ? active_element_color : Colors.primaryLightColor;
					// change text color to dark grey if we're using the 'outer elements', otherwise use light color
					const text_color = i == props.state && (element_symbol=='🜁' || element_symbol=='🜃') ? Colors.primaryColor : Colors.accentColor;
					return <MainButton
						key={`${props.key}_${i}`}
						onPress={(event) => {
							// if already selected, deselect
							if (props.state == i)
								props.setState(-1);
							// otherwise select
							else 
								props.setState(i);
						}}
						style={{width: 60, height: 30, margin:10, backgroundColor: color, marginHorizontal: 3, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}
						text_color={text_color}
					>
						{`${element_symbol} ${element_bigram}`}
					</MainButton>
				})
			}
		</View>
	);
}

const EightTrigramRenderRow = (props) => {

	const render_button = (i) => {
		const element_trigram = Elements.trigram_array[i];
		const element_icon = Elements.trigram_to_icon[element_trigram];
		// const active_element_color = Elements.colors[Elements.from_symbol[element_symbol]]

		// color in activated elements
		const color = i == props.state ? Colors.primaryColor : Colors.primaryLightColor;
		const text_color = Colors.accentColor;

		return <TouchableOpacity
			key={`${props.key}_${i}`}
			onPress={(event) => {
				// if already selected, deselect
				if (props.state == i)
					props.setState(-1);
				// otherwise select
				else 
					props.setState(i);
			}}
		>
			<View style={{ flexDirection:'row', width: 60, height: 30, margin:2, backgroundColor: color, marginHorizontal: 3, borderRadius: 15, justifyContent: 'space-evenly', alignItems: 'center', backgroundColor: color, marginBottom: 2, marginHorizontal: 2}}>
				<Text style={{color:text_color}}>{`${element_trigram}`}</Text>
				<UniversalIcon collection={element_icon.collection} name={element_icon.name} size={12} color={text_color} />
			</View>
		</TouchableOpacity>
		
		// <MainButton
		// 	key={`${props.key}_${i}`}
		// 	onPress={(event) => {
		// 		// if already selected, deselect
		// 		if (props.state == i)
		// 			props.setState(-1);
		// 		// otherwise select
		// 		else 
		// 			props.setState(i);
		// 	}}
		// 	style={{width: 60, height: 30, margin:10, backgroundColor: color, marginHorizontal: 3, borderRadius: 15, justifyContent: 'center', alignItems: 'center', backgroundColor: color, marginBottom: 2, marginLeft: 2}}
		// 	text_color={text_color}
		// >
		// 	{`${element_trigram}`} <UniversalIcon collection='fontisto' name='skyatlzs' size={24} color={color} />
		// </MainButton>
	}

	return (
		<View style={{flexDirection: 'column', marginVertical:7}}>
			<View style={{flexDirection: 'row'}}>
				{ // render item for first trigram row
					[...Array(4).keys()].map(i => {
						return render_button(i);
					})
				}
			</View>
			<View style={{flexDirection: 'row'}}>
				{ // render item for second trigram row
					[...Array(4).keys()].map(i => {
						i += 4;
						return render_button(i);
					})
				}
			</View>
		</View>
	);
}

const LookupScreen = (props) => {

	const scrollY = useRef(new Animated.Value(0)).current;

	// Segmented control analysis
	const [selectedIndex, setSelectedIndex] = useState(0);
	const documentPages = ['4 Elements', '8 Trigrams', 'Search'];

	// 4 element filter
	const [element_3_state, setElement_3_state] = useState(-1);
	const [element_2_state, setElement_2_state] = useState(-1);
	const [element_1_state, setElement_1_state] = useState(-1);

	// 8 trigram filter
	const [trigram_1_state, setTrigram_1_state] = useState(-1);
	const [trigram_2_state, setTrigram_2_state] = useState(-1);

	// keeping track of free-text filtering aspects
	const [filteredData, setFilteredData] = useState([]);
	const [queryString, setQueryString] = useState('');
	const [numberColumns, setNumberColumns] = useState(3);

	// taking care of variable window size
	const handleResize = () => setNumberColumns(Math.max(Math.floor(window.innerWidth/300), 2));
	const throttlehandleResize = useCallback(throttle(handleResize, 100), []);

	// keep focus on TextInput, if selected
	const queryStringInputRef = createRef();

	const navigateToHexagram = (hex, english) => {
		props.navigation.navigate('IChingLookup',{
			hexagram: hex,	
			english: english,
		});
	};

	useEffect(() => {
		if ( !Platform.OS == 'web' ) return;

		window.addEventListener('resize', throttlehandleResize);		
		return _ => {
			window.removeEventListener('resize', throttlehandleResize);
	  	}
	}, []);

	useEffect(() => {
		let data = IChingHexagrams;
		let filter_array = [];
		switch (selectedIndex) {
			case 0:
				// filter for elements
				filter_array = [
					{ state: element_1_state, from_ii: 4, to_ii: 6 },
					{ state: element_2_state, from_ii: 2, to_ii: 4 },
					{ state: element_3_state, from_ii: 0, to_ii: 2 }
				];
			
				for (let i=0;i<filter_array.length;i++){
					const { state, from_ii, to_ii } = filter_array[i]; // unpack
					if (state < 0) continue; // skip if filter non-active
					const binary = Elements.to_binary[Elements.element_array[state]]; // binary pull
					data = data.filter(x => { // filter on binary match at correct index
						const hex_binary = String(x.binary);

						// YHVH - [1,2,63,64]
						// Zodiac - [28, 43, 44, 23, 24, 27, 38,40,54,37,39,53]
						// YHVH + 12 SUR - [1, 28, 43, 44, 2, 23, 24, 27, 63, 38,40,54,64,37,39,53]
						// Layer 1 - [1, 28, 30, 55, 56, 62]
						// Layer 2 - 

						// FILTERING --> [64, 53, 12, 17, 25, 45].indexOf(x['hex']) >= 0 ;

						return binary == hex_binary.slice(from_ii,to_ii);
					});
				}

				// set filtered data
				setFilteredData(data);

				break;
			case 1:
				// filter for trigrams
				filter_array = [
					{ state: trigram_1_state, from_ii: 3, to_ii: 6 },
					{ state: trigram_2_state, from_ii: 0, to_ii: 3 }
				];
			
				for (let i=0;i<filter_array.length;i++){
					const { state, from_ii, to_ii } = filter_array[i]; // unpack
					if (state < 0) continue; // skip if filter non-active
					const binary = Elements.trigram_to_binary[Elements.trigram_array[state]]; // binary pull
					data = data.filter(x => { // filter on binary match at correct index
						const hex_binary = String(x.binary);
						return binary == hex_binary.slice(from_ii,to_ii);
					});
				}

				// set filtered data
				setFilteredData(data);
				break;
			case 2:
				// we handle this in the useEffect below
				break;
			default:
				break;
		}
	}, [selectedIndex, element_1_state, element_2_state, element_3_state, trigram_1_state, trigram_2_state]);

	useEffect(() => {
		let data = IChingHexagrams;
		let filter_array = [];
		if (selectedIndex != 2) return;

		data = data.filter(x => { // filter on binary match at correct index
			const hex_key = x.hex;

			// gather all fields to search on
			const hex_string = String(hex_key);
			const hex_english = x.english;

			const genekey_data = GenekeysIndex[hex_key];
			const { keyword, shadow, gift, siddhi, dilemma, repressive, reactive, codon, AA } = genekey_data;

			// apply quick match
			return quickMatch(queryString, [hex_string, hex_english, keyword, shadow, gift, siddhi, dilemma, repressive, reactive, codon, AA]);
		});

		// set filtered data
		setFilteredData(data);

	}, [selectedIndex, queryString]);

	// special useEffect to refocus input text box after update!
	useEffect(() => {
		// only when we're inputing to queryStringInputRef
		if (selectedIndex != 2) return;

		if(queryStringInputRef.current){
			queryStringInputRef.current.focus();
			console.log("FUCK YES");
		}
			
		
	}, [filteredData] );

	const quickMatch = (q_string, field_array) => {
		// go through array of fields, and seek if we find q_string as substring --> yes? return true
		let q = q_string.toLowerCase();

		// space seperate query string allowing for more word to match
		var q_parts = q.split(' ');

		for (let j=0; j<q_parts.length; j++) {
			let q_p = q_parts[j]; // query part

			// quick check for numbers -> use whole-string matching on field[0] (hexagram number)
			if ( !isNaN(q_p)){
				if (field_array[0] == q_p)
					return true;
			}
			
			// if not a number, do text look-up
			else {
				for (let i=0; i<field_array.length; i++) {
					let field = field_array[i];	

					if (field.toLowerCase().indexOf(q_p) !== -1)
						return true; // keep
				}
			}
		}
		return false; // filter out
	}

	const renderItem = ({item, index, seperators}) => {
		return <HexagramShortInfo item={item} onClick={navigateToHexagram} numberColumns={numberColumns}/>
	}

	const keyExtractor = (item) => {return `${item.hex}`}

	const render_digram_selection = () => {
		return (
			<View style={styles.filtering_content}>
				<View >
					<FourElementRenderRow id={'3§_3'} state={element_3_state} setState={setElement_3_state}/>
					<FourElementRenderRow id={'3§_2'} state={element_2_state} setState={setElement_2_state}/>
					<FourElementRenderRow id={'3§_1'} state={element_1_state} setState={setElement_1_state}/>
				</View>
			</View>
		);
	}

	const render_trigram_selection = () => {
		return (
			<View style={styles.filtering_content}>
				<View >
					<EightTrigramRenderRow id={'2§_2'} state={trigram_2_state} setState={setTrigram_2_state}/>
					<EightTrigramRenderRow id={'2§_1'} state={trigram_1_state} setState={setTrigram_1_state}/>
				</View>
			</View>
		);
	}

	const render_search_selection = () => {
		return (
			<View style={{flex:1, justifyContent: 'center', alignItems: 'center'}}>
				<DelayedTextInput
					onDelayedSubmit={setQueryString}
					text={queryString}
					ref={queryStringInputRef}
				/>
				<Text style={styles.text_normal}>Type in any hexagram number or keyword to look it up. Use spaces to search for multiple hits!</Text>
			</View>
		);
	}

	const ChoicePages = () => {
		switch (selectedIndex) {
			case 0: {
				return render_digram_selection();
			}
			case 1: {
				return render_trigram_selection();
			}
			case 2: {
				return render_search_selection();
			}
			default: {
				return render_digram_selection();
			}
		}
	}

	return (
		<View style={styles.screen}>
			<SafeAreaView style={{ flex: 0, backgroundColor: Colors.accentColor }} />
			<FocusAwareStatusBar barStyle="dark-content" backgroundColor={Colors.accentColor}/>
			{/* <View
				style={{width: '100%', flexDirection: 'row', backgroundColor:Colors.accentColor, flexWrap: 'wrap-reverse'}}
			>
				<Animated.View style={{
					flex:2,
					flexDirection: 'column',
					minWidth: 300,
					height: Dimensions.get('window').width > 540 ? 250 : scrollY.interpolate({
						inputRange: [0, 250],
						outputRange: [250, 0],
						extrapolateLeft: 'clamp',
						extrapolateRight: 'clamp'
					}),
					overflow: 'hidden'
				}}>
					<SegmentedControl
						values={documentPages}
						selectedIndex={selectedIndex}
						onChange={ event => setSelectedIndex(event.nativeEvent.selectedSegmentIndex) }
						style={{flex:-1, marginTop: 10, paddingVertical:-2, marginHorizontal:10}}
						fontStyle={{textAlign: 'center'}}
					/>
					<ChoicePages/>
				</Animated.View>

				<View style={{
					flex:1, flexDirection: 'column', minWidth:250
				}}>
					<Animated.View style={{
						justifyContent: 'center',
						alignItems: 'center',
						marginBottom: 5,
						paddingVertical: 10,
						height: Dimensions.get('window').width > 540 ? 250 : scrollY.interpolate({
							inputRange: [275,375],
							outputRange: [250,200],
							extrapolateLeft: 'clamp',
							extrapolateRight: 'clamp'
						}),
					}}>
						<AstroWheelSVG width={250} hexagram_list={filteredData} color_mode={'light'} />
					</Animated.View>
				</View>
			</View> */}

			<View style={styles.hexagram_content}>
				<Animated.FlatList
					style={styles.hexagram_list}
					// horizontal={true}
					data={filteredData}
					keyExtractor={keyExtractor}
					renderItem={renderItem}
					initialNumToRender={10}
					maxToRenderPerBatch={10}
					windowSize={20}
					numColumns={numberColumns}
					key={numberColumns}
					columnWrapperStyle={{
						flexWrap: 'wrap',
						textAlign: 'center',
						justifyContent: 'space-around'
					}}

					showsVerticalScrollIndicator={false}

					stickyHeaderIndices={[0]}
					ListHeaderComponent={<View
						style={{width: '100%', flexDirection: 'row', backgroundColor:Colors.accentColor, flexWrap: 'wrap-reverse'}}
					>
						<Animated.View style={{
							flex:2,
							flexDirection: 'column',
							minWidth: 300,
							height: Dimensions.get('window').width > 540 ? 250 : scrollY.interpolate({
								inputRange: [0, 250],
								outputRange: [250, 0],
								extrapolateLeft: 'clamp',
								extrapolateRight: 'clamp'
							}),
							overflow: 'hidden'
						}}>
							<SegmentedControl
								values={documentPages}
								selectedIndex={selectedIndex}
								onChange={ event => setSelectedIndex(event.nativeEvent.selectedSegmentIndex) }
								style={{flex:-1, marginTop: 10, paddingVertical:-2, marginHorizontal:10}}
								fontStyle={{textAlign: 'center'}}
							/>
							<ChoicePages/>
						</Animated.View>
		
						<View style={{
							flex:1, flexDirection: 'column', minWidth:250
						}}>
							<Animated.View style={{
								justifyContent: 'center',
								alignItems: 'center',
								marginBottom: 5,
								paddingVertical: 10,
								height: Dimensions.get('window').width > 540 ? 250 : scrollY.interpolate({
									inputRange: [275,375],
									outputRange: [250,200],
									extrapolateLeft: 'clamp',
									extrapolateRight: 'clamp'
								}),
							}}>
								<AstroWheelSVG  width={250} hexagram_list={filteredData} color_mode={'light'} />
							</Animated.View>
						</View>
					</View>}

					// contentContainerStyle={{
					// 	paddingTop: scrollY.interpolate({
					// 		inputRange: [0,250],
					// 		outputRange: [0,250],
					// 		extrapolateLeft: 'clamp',
					// 		extrapolateRight: 'clamp'
					// 	})
					// }}

					// ListHeaderComponent={<Animated.View style={{height:scrollY.interpolate({
					// 	inputRange: [0,250],
					// 	outputRange: [0,250],
					// 	extrapolateLeft: 'clamp',
					// 	extrapolateRight: 'clamp'
					// })}}/>}

					onScroll={
						Animated.event([
							{
								nativeEvent: {
									contentOffset: {
										y: scrollY
									}
								}
							}
						],
						{ 
							useNativeDriver: true
						})
					}

				/>
			</View>

		</View>
	);
}

const styles = StyleSheet.create({
	screen: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: Colors.accentColor,
	},

	element_btn_sel: {
		width: 150,
		height: 75,
		// color: Colors.accentColor,
		// fontSize: 30
		margin: 10
	},

	trigram_btn_sel: {
		width: 60,
		height: 40,
		// color: Colors.accentColor,
		// fontSize: 30
		margin: 5
	},

	filter_header_text: {
		color: Colors.primaryColor,
		fontSize: 16,
		fontFamily: 'noticia',
		margin:5
	},

	filtering_content: {
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		// width: Dimensions.get('window').width
	},	

	hexagram_content: {
		flex: 3,
		flexDirection: 'column',
		backgroundColor: Colors.primaryColor,
	
		width: '100%',

		borderTopLeftRadius: 40,
		borderTopRightRadius: 40,
	
		// paddingTop: 15,
		...Platform.select({
				ios: {
			paddingTop: Dimensions.get('window').width < 400 ? 10 : 0,
				},
				android: {
					paddingTop: 10,
				},
			}),

		overflow: 'hidden'
	},

	hexagram_list: {
		// flex: 1,
		// justifyContent: 'center',
		// alignItems: 'center'
	},

	visual_information: {
		flexDirection: 'row',
		width: '100%',
		height: 100,
		alignItems: 'center',
		justifyContent: 'space-around',

		backgroundColor: Colors.primaryColor,
		marginVertical: 10
	},

	hexagram_item: {
		flexDirection: 'column',
		width: '100%',
		// height: 200,
		alignItems: 'center',
		justifyContent: 'space-evenly',

		backgroundColor: Colors.primaryColor,
		marginBottom: 5,
		padding: 5
	},

	smallprint_info: {
		color: Colors.accentColor,
		fontSize: 10,
		fontFamily: 'prompt-light'
	},

	header_text: {
		color: Colors.accentColor,
		fontSize: 15,
		fontFamily: 'prompt-regular'
	},

	text_normal: {
		color: Colors.primaryLightColor,
		fontSize: 12,
		fontFamily: 'prompt-regular',
		textAlign: 'center',
		margin:10,
	},

	genekey_header: {
		color: Colors.accentColor,
		fontSize: 15,
		fontFamily: 'prompt-regular',
		// justifyContent: 'space-around'
	},
});

export default LookupScreen;