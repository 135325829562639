export default {
    1:{"hex":1,  "keyword":"From Entropy To Syntropy", "shadow": "Entropy", "gift":"Freshness", "siddhi":"Beauty", "dilemma":"Numbness", "repressive": "Depressive", "reactive":"Frenetic", "codon":"The Ring of Fire", "AA":"Lysine"},
    2:{"hex":2,  "keyword":"Returning To The One", "shadow": "Dislocation", "gift":"Orientation", "siddhi":"Unity", "dilemma":"Agenda", "repressive": "Lost", "reactive":"Regimented", "codon":"The Ring of Water", "AA":"Phenylalanine"},
    3:{"hex":3,  "keyword":"Through The Eyes Of A Child", "shadow": "Innocence", "gift":"Innovation", "siddhi":"Chaos", "dilemma":"Clinging", "repressive": "Anal", "reactive":"Disordered", "codon":"The Ring Of Life And Death", "AA":"Leucine"},
    4:{"hex":4,  "keyword":"A Universal Panacea", "shadow": "Intolerance", "gift":"Understanding", "siddhi":"Forgiveness", "dilemma":"Reasons", "repressive": "Apathetic", "reactive":"Nit-Picking", "codon":"The Ring of Union", "AA":"Valine"},
    5:{"hex":5,  "keyword":"The Ending Of Time", "shadow": "Impatience", "gift":"Patience", "siddhi":"Timelessness", "dilemma":"Surrender", "repressive": "Pessimistic", "reactive":"Pushy", "codon":"The Ring of Light", "AA":"Threonine"},
    6:{"hex":6,  "keyword":"The Path To Peace", "shadow": "Conflict", "gift":"Diplomacy", "siddhi":"Peace", "dilemma":"Protection", "repressive": "Over-Attentive", "reactive":"Tactless", "codon":"The Ring of Alchemy", "AA":"Glycine"},
    7:{"hex":7,  "keyword":"Virtue Is Its Own Reward", "shadow": "Division", "gift":"Guidance", "siddhi":"Virtue", "dilemma":"Boundaries", "repressive": "Hidden", "reactive":"Dictatorial", "codon":"The Ring of Union", "AA":"Valine"},
    8:{"hex":8,  "keyword":"Diamond Of The Self", "shadow": "Mediocrity", "gift":"Style", "siddhi":"Exquisiteness", "dilemma":"Imitation", "repressive": "Wooden", "reactive":"Artificial", "codon":"The Ring of Water", "AA":"Phenylalanine"},
    9:{"hex":9,  "keyword":"The Power Of The Infinitesimal", "shadow": "Inertia", "gift":"Determination", "siddhi":"Invincibility", "dilemma":"Percpective", "repressive": "Reluctant", "reactive":"Diverted", "codon":"The Ring of Light", "AA":"Threonine"},
    10:{"hex":10, "keyword":"Being At Ease", "shadow": "Self-Obsession", "gift":"Naturalness", "siddhi":"Being", "dilemma":"Tightness", "repressive": "Self-Denying", "reactive":"Narcissistic", "codon":"The Ring of Humanity", "AA":"Arginine"},
    11:{"hex":11, "keyword":"The Light Of Eden", "shadow": "Obscurity", "gift":"Idealism", "siddhi":"Light", "dilemma":"Belief", "repressive": "Fantasising", "reactive":"Deluded", "codon":"The Ring of Light", "AA":"Threonine"},
    12:{"hex":12, "keyword":"A Pure Heart", "shadow": "Vanity", "gift":"Discrimination", "siddhi":"Purity", "dilemma":"Aloneness", "repressive": "Elitist", "reactive":"Malicious", "codon":"The Ring of Trails", "AA":"STOP"},
    13:{"hex":13, "keyword":"Listening Through Love", "shadow": "Discord", "gift":"Discernment", "siddhi":"Empathy", "dilemma":"Pessimism", "repressive": "Permissive", "reactive":"Narrow-Minded", "codon":"The Ring of Purification", "AA":"Glutamine"},
    14:{"hex":14, "keyword":"Radiating Prosperity", "shadow": "Compromise", "gift":"Competence", "siddhi":"Bounteousness", "dilemma":"Self Belief", "repressive": "Impotent", "reactive":"Enslaved", "codon":"The Ring of Fire", "AA":"Lysine"},
    15:{"hex":15, "keyword":"An Eternally Flowering Spring", "shadow": "Dullness", "gift":"Magnetism", "siddhi":"Florescence", "dilemma":"Comfort", "repressive": "Empty", "reactive":"Extremist", "codon":"The Ring of Seeking", "AA":"Serine"},
    16:{"hex":16, "keyword":"Magical Genius", "shadow": "Indifference", "gift":"Versatility", "siddhi":"Mastery", "dilemma":"Laziness", "repressive": "Gullible", "reactive":"Self-Deluded", "codon":"The Ring of Prosperity", "AA":"Cysteine"},
    17:{"hex":17, "keyword":"The Eye", "shadow": "Opinion", "gift":"Far-Sightedness", "siddhi":"Omniscience", "dilemma":"Politics", "repressive": "Self-Critical", "reactive":"Opinionated", "codon":"The Ring of Humanity", "AA":"Arginine"},
    18:{"hex":18, "keyword":"The Healing Power Of Mind", "shadow": "Judgement", "gift":"Integrity", "siddhi":"Perfection", "dilemma":"Flaws", "repressive": "Inferiority", "reactive":"Superiority", "codon":"The Ring of Matter", "AA":"Alanine"},
    19:{"hex":19, "keyword":"The Future Human Being", "shadow": "Co-Dependence", "gift":"Sensitivity", "siddhi":"Sacrifice", "dilemma":"Heresy", "repressive": "Needy", "reactive":"isolated", "codon":"The Ring of Gaia", "AA":"Isoleucine"},
    20:{"hex":20, "keyword":"The Sacred Om", "shadow": "Superficiality", "gift":"Self Assurance", "siddhi":"Presence", "dilemma":"Consideration", "repressive": "Absent", "reactive":"Hectic", "codon":"The Ring of Life and Death", "AA":"Leucine"},
    21:{"hex":21, "keyword":"A Noble Life", "shadow": "Control", "gift":"Authority", "siddhi":"Valour", "dilemma":"Discipline", "repressive": "Submissive", "reactive":"Controlling", "codon":"The Ring of Humanity", "AA":"Arginine"},
    22:{"hex":22, "keyword":"Grace Under Pressure", "shadow": "Dishonour", "gift":"Graciousness", "siddhi":"Grace", "dilemma":"Accountability", "repressive": "Proper", "reactive":"Inappropriate", "codon":"The Ring of Divinity", "AA":"Proline"},
    23:{"hex":23, "keyword":"The Alchemy Of Simplicity", "shadow": "Complexity", "gift":"Simplicity", "siddhi":"Quintessence", "dilemma":"Timing", "repressive": "Dumb", "reactive":"Fragmented", "codon":"The Ring Of Life And Death", "AA":"Leucine"},
    24:{"hex":24, "keyword":"Silence - The Ultimate Addiction", "shadow": "Addiction", "gift":"Invention", "siddhi":"Silence", "dilemma":"Gravity", "repressive": "Frozen", "reactive":"Anxious", "codon":"The Ring Of Life And Death", "AA":"Leucine"},
    25:{"hex":25, "keyword":"The Myth Of The Sacred Wound", "shadow": "Constriction", "gift":"Acceptance", "siddhi":"Universal Love", "dilemma":"Anxiety", "repressive": "Ignorant", "reactive":"Cold", "codon":"The Ring of Humanity", "AA":"Arginine"},
    26:{"hex":26, "keyword":"Sacred Tricksters", "shadow": "Pride", "gift":"Artfulness", "siddhi":"Invisibility", "dilemma":"Lack", "repressive": "Manipulative", "reactive":"Boastful", "codon":"The Ring of Light", "AA":"Threonine"},
    27:{"hex":27, "keyword":"Food Of The Gods", "shadow": "Selfishness", "gift":"Altruism", "siddhi":"Selflessness", "dilemma":"Consideration", "repressive": "Self-Sacrificing", "reactive":"Self-Centred", "codon":"The Ring of Life And Death", "AA":"Leucine"},
    28:{"hex":28, "keyword":"Embracing The Dark Side", "shadow": "Purposelessness", "gift":"Totality", "siddhi":"Immortality", "dilemma":"Avoidance", "repressive": "Hollow", "reactive":"Gambling", "codon":"The Ring of Illusion", "AA":"Asparaginic Acid"},
    29:{"hex":29, "keyword":"Leaping Into The Void", "shadow": "Half-Heartedness", "gift":"Freshness", "siddhi":"Beauty", "dilemma":"Numbness", "repressive": "Depressive", "reactive":"Frenetic", "codon":"The Ring of Fire", "AA":"Lysine"},
    30:{"hex":30, "keyword":"Celestial Fire", "shadow": "Desire", "gift":"Lightness", "siddhi":"Rapture", "dilemma":"Temptation", "repressive": "Over-Serious", "reactive":"Flippant", "codon":"The Ring of Purification", "AA":"Glutamine"},
    31:{"hex":31, "keyword":"Sounding Your Truth", "shadow": "Arrogance", "gift":"Leadership", "siddhi":"Humility", "dilemma":"Choice", "repressive": "Deferring", "reactive":"Scornful", "codon":"The Ring of No Return", "AA":"Tyrosine"},
    32:{"hex":32, "keyword":"Ancestral Reverence", "shadow": "Failure", "gift":"Preservation", "siddhi":"Veneration", "dilemma":"Panic", "repressive": "Fundamentalist", "reactive":"Disjointed", "codon":"The Ring of Illusion", "AA":"Asparaginic Acid"},
    33:{"hex":33, "keyword":"The Final Revelation", "shadow": "Forgetting", "gift":"Mindfulness", "siddhi":"Revelation", "dilemma":"Attention", "repressive": "Reserved", "reactive":"Censorious", "codon":"The Ring of Trails", "AA":"STOP"},
    34:{"hex":34, "keyword":"The Beauty Of The Beast", "shadow": "Force", "gift":"Strength", "siddhi":"Majesty", "dilemma":"Trying", "repressive": "Self-Effacing", "reactive":"Bullish", "codon":"The Ring of Destiny", "AA":"Asparagine"},
    35:{"hex":35, "keyword":"Wormholes And Miracles", "shadow": "Hunger", "gift":"Adventure", "siddhi":"Boundlessness", "dilemma":"Self Indulgence", "repressive": "Bored", "reactive":"Manic", "codon":"The Ring of Miracles", "AA":"Tryptophan"},
    36:{"hex":36, "keyword":"Becoming Human", "shadow": "Turbulence", "gift":"Humanity", "siddhi":"Compassion", "dilemma":"Overwhelm", "repressive": "Nervousness", "reactive":"Crisis Prone", "codon":"The Ring of Divinity", "AA":"Proline"},
    37:{"hex":37, "keyword":"Family Alchemy", "shadow": "Weakness", "gift":"Equality", "siddhi":"Tenderness", "dilemma":"Submission", "repressive": "Over-Sentimental", "reactive":"Cruel", "codon":"The Ring of Divinity", "AA":"Proline"},
    38:{"hex":38, "keyword":"The Warrior Of Light", "shadow": "Struggle", "gift":"Perseverance", "siddhi":"Honour", "dilemma":"Habit", "repressive": "Defeatist", "reactive":"Aggressive", "codon":"The Ring of Humanity", "AA":"Arginine"},
    39:{"hex":39, "keyword":"The Tension Of Transcendence", "shadow": "Provocation", "gift":"Dynamism", "siddhi":"Liberation", "dilemma":"Blockages", "repressive": "Trapped", "reactive":"Provocative", "codon":"The Ring of Seeking", "AA":"Serine"},
    40:{"hex":40, "keyword":"The Will To Surrender", "shadow": "Exhaustion", "gift":"Resolve", "siddhi":"Divine Will", "dilemma":"Excess", "repressive": "Acquiescent", "reactive":"Contemptuous", "codon":"The Ring of Alchemy", "AA":"Glycine"},
    41:{"hex":41, "keyword":"The Prime Emanation", "shadow": "Fantasy", "gift":"Anticipation", "siddhi":"Emanation", "dilemma":"Planning", "repressive": "Dreamy", "reactive":"Hyperactive", "codon":"The Ring of Origin", "AA":"Methionine"},
    42:{"hex":42, "keyword":"Letting Go of Living And Dying", "shadow": "Expectation", "gift":"Detachment", "siddhi":"Celebration", "dilemma":"Disapointment", "repressive": "Grasping", "reactive":"Flaky", "codon":"The Ring of Life and Death", "AA":"Leucine"},
    43:{"hex":43, "keyword":"Breakthrough", "shadow": "Deafness", "gift":"Insight", "siddhi":"Epiphany", "dilemma":"Knowing", "repressive": "Worried", "reactive":"Noisy", "codon":"The Ring of Destiny", "AA":"Asparagine"},
    44:{"hex":44, "keyword":"Karmic Relationships", "shadow": "Interference", "gift":"Teamwork", "siddhi":"Synarchy", "dilemma":"Hierarchy", "repressive": "Distrustful", "reactive":"Misjudging", "codon":"The Ring of Illuminatie", "AA":"Glutamic Acid"},
    45:{"hex":45, "keyword":"Cosmic Communion", "shadow": "Dominance", "gift":"Synergy", "siddhi":"Communion", "dilemma":"Trust", "repressive": "Timid", "reactive":"Pompous", "codon":"The Ring of Prosperity", "AA":"Cysteine"},
    46:{"hex":46, "keyword":"A Science of Luck", "shadow": "Seriousness", "gift":"Delight", "siddhi":"Ecstasy", "dilemma":"Numbness", "repressive": "Depressive", "reactive":"Frenetic", "codon":"The Ring of Fire", "AA":"Lysine"},
    47:{"hex":47, "keyword":"Transmuting The Past", "shadow": "Oppression", "gift":"Transmutation", "siddhi":"Transfiguration", "dilemma":"Ownership", "repressive": "Hopeless", "reactive":"Dogmatic", "codon":"The Ring of Alchemy", "AA":"Glycine"},
    48:{"hex":48, "keyword":"The Wonder of Uncertainty", "shadow": "Inadequacy", "gift":"Resourcefulness", "siddhi":"Wisdom", "dilemma":"Not-Knowing", "repressive": "Bland", "reactive":"Unscrupulous", "codon":"The Ring of Matter", "AA":"Alanine"},
    49:{"hex":49, "keyword":"Changing THe World From The Inside", "shadow": "Reaction", "gift":"Revolution", "siddhi":"Rebirth", "dilemma":"Needs", "repressive": "Inert", "reactive":"Rejecting", "codon":"The Ring of The Whirlwind", "AA":"Histidine"},
    50:{"hex":50, "keyword":"Cosmic Order", "shadow": "Corruption", "gift":"Equilibrium", "siddhi":"Harmony", "dilemma":"Resignation", "repressive": "Overloaded", "reactive":"Irresponsible", "codon":"The Ring of The Illuminati", "AA":"Glutamic Acid"},
    51:{"hex":51, "keyword":"Initiative to Initiation", "shadow": "Agitation", "gift":"Initiative", "siddhi":"Awakening", "dilemma":"Harshness", "repressive": "Cowardly", "reactive":"Hostile", "codon":"The Ring of Humanity", "AA":"Arginine"},
    52:{"hex":52, "keyword":"The Stillpoint", "shadow": "Stress", "gift":"Restraint", "siddhi":"Stillness", "dilemma":"Shallow Breathing", "repressive": "Stuck", "reactive":"Restless", "codon":"The Ring of Seeking", "AA":"Serine"},
    53:{"hex":53, "keyword":"Evolving Beyond Evolution", "shadow": "Immaturity", "gift":"Expansion", "siddhi":"Superabundance", "dilemma":"Restlessness", "repressive": "Solemn", "reactive":"Frenetic", "codon":"The Ring of Seeking", "AA":"Serine"},
    54:{"hex":54, "keyword":"The Serpent Path", "shadow": "Greed", "gift":"Aspiration", "siddhi":"Ascension", "dilemma":"Egotism", "repressive": "Un-Ambitious", "reactive":"Greedy", "codon":"The Ring of Seeking", "AA":"Serine"},
    55:{"hex":55, "keyword":"The Dragonfly's Dream", "shadow": "Victimisation", "gift":"Freedom", "siddhi":"Freedom", "dilemma":"External Validation", "repressive": "Complaining", "reactive":"Blaming", "codon":"The Ring of Whirlwind", "AA":"Histidine"},
    56:{"hex":56, "keyword":"Divine Indulgence", "shadow": "Distraction", "gift":"Enrichment", "siddhi":"Intoxication", "dilemma":"Pleasure/Pain", "repressive": "Sullen", "reactive":"Overstimulated", "codon":"The Ring of Trails", "AA":"STOP"},
    57:{"hex":57, "keyword":"A Gentle Wind", "shadow": "Unease", "gift":"Intuition", "siddhi":"Clarity", "dilemma":"Trust", "repressive": "Hesitant", "reactive":"Impetuous", "codon":"The Ring of Matter", "AA":"Alanine"},
    58:{"hex":58, "keyword":"From Stress To Bliss", "shadow": "Dissatisfaction", "gift":"Vitality", "siddhi":"Bliss", "dilemma":"Rhythm", "repressive": "None", "reactive":"Interfering", "codon":"The Ring of Seeking", "AA":"Serine"},
    59:{"hex":59, "keyword":"The Dragon In Your Genome", "shadow": "Dishonesty", "gift":"Intimacy", "siddhi":"Transparency", "dilemma":"Emotional Processing", "repressive": "Excluded", "reactive":"Intrusive", "codon":"The Ring of Union", "AA":"Valine"},
    60:{"hex":60, "keyword":"The Cracking Of The Vessel", "shadow": "Limitation", "gift":"Realism", "siddhi":"Justice", "dilemma":"Balance", "repressive": "Unstructured", "reactive":"Rigid", "codon":"The Ring of Gaia", "AA":"Isoleucine"},
    61:{"hex":61, "keyword":"The Holy Of Holies", "shadow": "Psychosis", "gift":"Inspiration", "siddhi":"Sanctity", "dilemma":"Knowledge", "repressive": "Disenchanted", "reactive":"Fanatical", "codon":"The Ring of Gaia", "AA":"Isoleucine"},
    62:{"hex":62, "keyword":"The Language Of Light", "shadow": "Intellect", "gift":"Precision", "siddhi":"Impeccability", "dilemma":"Facts", "repressive": "Obsessive", "reactive":"Pedantic", "codon":"The Ring of No Return", "AA":"Tyrosine"},
    63:{"hex":63, "keyword":"Reaching The Source", "shadow": "Doubt", "gift":"Inquiry", "siddhi":"Truth", "dilemma":"Logic", "repressive": "Self-Doubt", "reactive":"Suspicion", "codon":"The Ring of Divinity", "AA":"Proline"},
    64:{"hex":64, "keyword":"The Aurora", "shadow": "Confusion", "gift":"Imagination", "siddhi":"Illumination", "dilemma":"Consistency", "repressive": "Imitation", "reactive":"Confused", "codon":"The Ring of Alchemy", "AA":"Glycine"},
};