import React, {useState} from 'react';
import { View, Text, StyleSheet, FlatList, StatusBar } from 'react-native';
// import { SafeAreaView } from 'react-navigation';

// Get Now Time
// import Moment from 'moment-timezone';

// Helpers
import { getEphemeris } from '../helpers/astrology';

import _ from 'lodash';
import Colors from '../constants/Colors.js';
import Elements from '../constants/elements';

import FocusAwareStatusBar from './FocusAwareStatusBar';

const TransitScreen = (props) => {

	// Ephemeris
	const [ephemeris, setEphemeris] = useState('empty');
	const [ephemerisBirth, setEphemerisBirth] = useState('empty');

	const getNewEphemeris = () => {
		const showPlanets = true;
		const location = {
			"coords": {
			  "accuracy": 5,
			  "altitude": 0,
			  "altitudeAccuracy": -1,
			  "heading": 270.74,
			  "latitude": 37.32589393,
			  "longitude": -122.02570387,
			  "speed": 3.38,
			},
			"timestamp": 1599417276467.0469,
		  }
		let newDate = new Date();
		setEphemeris( getEphemeris(newDate, showPlanets, location) );
		setEphemerisBirth( getEphemeris(newDate, showPlanets, location) );
	}

	if (ephemeris == 'empty'){
		getNewEphemeris();
		return <View><Text>No ephemeris</Text></View>
	}
	
	// console.log(ephemeris);

	const get_element_style = (element) => {
		return {
			color: Elements.colors[Elements.from_symbol[element]],
			fontSize: 20
		};
	}

	const renderElemental = (elements, elements_2) =>{
		return (
			<View style={{flexDirection: 'row', justifyContent:'center'}}>
				<View style={{flexDirection: 'col'}}>
					<Text style={get_element_style(elements[0])}>{elements[0]}</Text>
					<Text style={get_element_style(elements[1])}>{elements[1]}</Text>
					<Text style={get_element_style(elements[2])}>{elements[2]}</Text>
				</View>
				<View style={{flexDirection: 'col'}}>
					<Text style={get_element_style(elements_2[0])}>{elements_2[0]}</Text>
					<Text style={get_element_style(elements_2[1])}>{elements_2[1]}</Text>
					<Text style={get_element_style(elements_2[2])}>{elements_2[2]}</Text>
				</View>	
			</View>
		)
	}

	const renderItem = (data_1, data_2) => {

		const hex_transit = `${data_1['hexagram_num']}.${data_1['hexagram_line']}`
		const hex_birth = `${data_2['hexagram_num']}.${data_2['hexagram_line']}`

		return (
			<View style={styles.planet}>
				<Text style={styles.symbol_text}>{data_1['symbol']}</Text>
				<Text style={styles.symbol_text}>{hex_transit}</Text>
				<View style={styles.elements}>
					{renderElemental(data_1['pattern'], data_2['pattern'])}
					{/* <Text style={styles.text_2}>{hex_transit + ' ' + data_1['element_compound']}</Text> */}
					{/* <Text style={styles.text_2}>{hex_birth + ' ' + data_2['element_compound']}</Text> */}
				</View>
				<Text style={styles.symbol_text}>{hex_birth}</Text>
			</View>
		)
	}

	const renderPlanets = (ephemeris) => {
		const planets = ['sun', 'earth', 'moon', 'mercury', 'venus', 'mars', 'jupiter']

		return _.map(planets, (p) => {
			return <View key={p}>{renderItem(ephemeris[p], ephemerisBirth[p])}</View>
		});

	}

	// let isFocused = props.navigation.isFocused();

	return (
		<View style={styles.screen}>

			<FocusAwareStatusBar barStyle="light-content"/>

			{/* { isFocused ? <StatusBar barStyle="dark-content" /> : null} */}

			{/* <SafeAreaView style={{ flex: 0, backgroundColor: Colors.accentColor }} /> */}

			{renderPlanets(ephemeris)}
		</View>
	);
}

// TransitScreen.navigationOptions = (navOptions) => {
// 	return {
// 		tabBarOptions: {
// 			activeTintColor: Colors.accentColor,
// 			tabStyle: {
// 				'backgroundColor': Colors.primaryColor
// 			},
// 			safeAreaInset: { bottom: 'never', top: 'never' }
// 		}
// 	};
// };

const styles = StyleSheet.create({
	screen: {
		flex: 1,
		justifyContent: 'space-evenly',
		alignItems: 'center',
		backgroundColor: Colors.primaryColor
	},

	planet: {
		flexDirection: 'row',
		justifyContent: 'center',
		width: '100%'
	},

	symbol_text: {
		color: 'white',
		fontSize: 30,
		flex: 1,
		fontFamily: 'noticia',

		// align from left
		paddingLeft: 20
	},

	text_2: {
		color: 'white',
		fontSize: 20,
		lineHeight: 20,
		flex: 2,
		fontFamily: 'noticia',
	},

	elements: {
		flex: 2,
		flexDirection: 'column',
		justifyContent: 'space-between'
	}
});

export default TransitScreen;