import React, { useState, useEffect, useCallback } from 'react';
import { View, Text } from 'react-native';
import { Fontisto, FontAwesome5, Ionicons } from '@expo/vector-icons';

const UniversalIcon = (props) => {
	const { collection, name, size, color } = props;

	const renderIcon = () => {
		console.log(collection);
		if ( collection == 'fontisto' )
			return <Fontisto name={name} size={size} color={color} />
		else if ( collection == 'fontawesome5' )
			return <FontAwesome5 name={name} size={size} color={color}/>
		else if (collection == 'ionicons')
			return <Ionicons name={name} size={size} color={color}/>
		else {
			return <Fontisto name="skyatlas" size={15} color="black" />
		}
	}

	return (
		<View>
			{renderIcon()}
		</View>
	);
};

export default UniversalIcon;