import React from 'react';
import {View, FlatList, StyleSheet, Platform, Dimensions} from 'react-native';
import PlanetInfo from './PlanetInfo';
import PlanetInfoWidget from './PlanetInfoWidget';

const planet_item = (planet, handleNavigate, vertical) => {
	// Used for the flatlist, which displays data neatly and cleanly
	return <PlanetInfo onPress={handleNavigate} planet={planet} vertical={vertical}/>
}

const planet_item_widget = (planet, handleNavigate, vertical) => {
	// Used for the flatlist, which displays data neatly and cleanly
	return <PlanetInfoWidget onPress={handleNavigate} planet={planet} vertical={vertical}/>
}

const PlanetList = (props) => {

	const { data, handleNavigate, style } = props;
	const widget = props.widget ?? false; // are we rendering a widget?
	const vertical = props.vertical === undefined ? false : props.vertical;

	return (
		<View style={{...styles.planetary_container, ...style}}>
			<FlatList
				style={{
					margin: 0,
					padding: 0,
				}}
				horizontal={vertical == false}
				data={data}
				keyExtractor={item => item.planet_name}
				renderItem={
					({item, index, seperators}) => {
						return widget
							? planet_item_widget(item, handleNavigate, vertical) // if widget
							: planet_item(item, handleNavigate, vertical) // if normal
					}
				}
				// showsHorizontalScrollIndicator='false'
				snapToAlignment="start"
				snapToInterval={Dimensions.get('window').width}
				decelerationRate='fast'
				widget={widget}
			/>
		</View>
	);
}

const styles = StyleSheet.create({
	planetary_container: {
		alignItems: 'center',
		justifyContent: 'space-around',
		// flex: 1,
		flexDirection: 'row',
		// height: 75
	},
});

export default PlanetList;