import React, {useState, useRef, useEffect}  from 'react';
import { View, Text, StyleSheet, Animated, Easing, Platform } from 'react-native';

import Svg, { Circle, Path, G, Line, Text as TextSVG } from 'react-native-svg';
import _, { filter } from 'lodash';

import Colors from '../../constants/Colors';

import IChingWilhelm from '../../iching-wilhelm-dataset/data/iching_wilhelm_translation';
import Genekeys from '../../genekeys-dataset/genekeys';

import * as Font from 'expo-font';

// This component has 3 parts
// 1. The Outline SVG
// 2. The Segments SVG
// 3. The Lines SVG

import Elements from '../../constants/elements';
const PHI = 1.6180339887;

const getCoordinatesForRadius = (center, angle, radius) => {
	const x = center[0] + Math.cos(angle)*radius;
	const y = center[1] + Math.sin(angle)*radius;
	return [x, y];
}

const ElementWheelSVG = (props) => {

	// const time = props.time ??  0;

	// const time = props.time ? props.time.interpolate({
	// 	inputRange: [0, 140],
	// 	outputRange: [0,2*3.1415],//['rgba(0,0,0,0.0)', Color.HEADER_COLOR],
	// 	extrapolate: 'clamp',
	// 	useNativeDriver: true,
	// }) : 0;

	const { hexagram, hexagram_list, hexagram_object, color_mode } = props;

	// console.log("hexagram", hexagram_object);

	const container_width = props.width;
	const width = props.planet_mode == true ? props.width*0.85 : props.width;

	// dark / white mode
	const color_lines = color_mode=='light' ? Colors.colorMidLight : Colors.primaryColor;

	 // drawing center - center of the wheel
	const c = [container_width/2, container_width/2];


	console.log('UPDATE RENDER', c, props.width, props.planet_mode);

	// useEffect(() => {
	// 	if (props.time !== undefined)
	// 		console.log(time)
	// 		// time.addListener(({value}) => this._value = value);
	// }
	// ,[time]);

	// console.log(time);

	// concentric circle definitions
	// const w = width/2*1.05;
	// const circles_radiuses = [
	// 	w/PHI**3, // 0
	// 	w/PHI**2.1,
	// 	w/PHI**1.5,  // 1
	// 	w/PHI**1.05,
	// 	w/PHI**(1/1.5),  // 2
	// 	w/PHI**(1/2.25),
	// 	w/PHI**(1/3.5),
	// 	props.middle_circle == false ? 0 : 2
	// ];

	const w = width/2*1.05;
	const circles_radiuses = [
		w/PHI**3, // 0
		w/PHI**2.1,
		w/PHI**1.5,  // 1
		w/PHI**1.05,
		w/PHI**(1/1.5),  // 2
		w/PHI**(1/2.25),
		w/PHI**(1/3.5),
		props.middle_circle == false ? 0 : 2
	];

	const stroke_width = container_width/450.0;
	const stroke_w_dials = container_width/450.0*1.25;

	const font_size = container_width/40;
	const font_size_planet = container_width/25;

	const outer_circle_color = '#666';

	// here we store the 'ephemeris / hexagram binaries' to display on the elemental wheel
	const [binaries, setBinaries] = useState([{binary: '000000', hex:1, angle_lon:0}]);

	// run after one of the dependencies changes value
	useEffect(() => {

		// if single digit, show single digit
		if (hexagram !== undefined){
			const v = IChingWilhelm[parseInt(hexagram)]
			setBinaries( [{binary: v.binary, hex:v.hex}] );
		}

		// if list, parse list!
		else if (hexagram_list !== undefined)
			setBinaries( _.map(hexagram_list, h => {
				return {
					binary:h['binary'],
					hex: h.hex
				}
			}));

		// this one is for TimeScreen, whose ephemeris is a list of planets, and a date object... probably a sleeker way of drawing the planets needs to be found!
		// this may slow down the application calculatation a ton!
		else if (hexagram_object !== undefined) {

			const hexes = _.map(hexagram_object, (h,key) => {
				if (key !== 'date') {
					return {
						binary:h.binary,
						hex: h.hexagram,
						line: h.hexagram_line,
						planet_name: key,
						planet_symbol: Elements.planet_symbol_font[key],
						angle_lon: h.radians,
						color: Elements.planet_q_colors[key]
					};
				}
			});

			setBinaries(hexes.filter(item => !!item));
		}

	}, [hexagram_list, hexagram_object]);

	// const binaries = hexagram_list !== undefined ? _.map(hexagram_list, h => IChingWilhelm[h]['binary'] ) : [IChingWilhelm[hexagram]['binary']];

	function shadeColor(color, percent) {

		var R = parseInt(color.substring(1,3),16);
		var G = parseInt(color.substring(3,5),16);
		var B = parseInt(color.substring(5,7),16);
	
		R = parseInt(R * (100 + percent) / 100);
		G = parseInt(G * (100 + percent) / 100);
		B = parseInt(B * (100 + percent) / 100);
	
		R = (R<255)?R:255;  
		G = (G<255)?G:255;  
		B = (B<255)?B:255;  
	
		var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
		var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
		var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));
	
		return "#"+RR+GG+BB;
	}
	  

	const draw_outer_circles = () => {
		return (
			<G key="circles">
				{
					_.map(circles_radiuses.slice(0,-2), r => <Circle
						cx={c[0]}
						cy={c[1]}
						r={r}
						fill={'#0000'}
						stroke={outer_circle_color}
						strokeWidth={stroke_width}
						key={`circle_r3_${r}`}
					/>)
				}
				{ props.planet_mode && <Circle
					cx={c[0]}
					cy={c[1]}
					r={2}
					fill={Colors.accentColor}
					key={`circle_middle`}
				/>}
			</G>
		);
	}

	const getCoordinatesForRadius = (center, angle, radius) => {
		const x = center[0] + Math.cos(angle)*radius;
		const y = center[1] + Math.sin(angle)*radius;
		return [x, y];
	}

	const draw_slice = (center, a1, a2, r1, r2, color, element, hex) => {
		// given angle_start & angle_stop, draw pie chart around center, with radius and color specified
		// a1 = angle start
		// a2 = angle stop
		// r2 = outer radius
		// r1 = inner radius
		const [move_X, move_Y]   = getCoordinatesForRadius(center, a1, r2); // start here
		const [arc_1_X, arc_1_Y] = getCoordinatesForRadius(center, a2, r2); // arc to here
		const [line_X, line_Y]   = getCoordinatesForRadius(center, a2, r1); // line to here (to inner radius)
		const [arc_2_X, arc_2_Y] = getCoordinatesForRadius(center, a1, r1); // arc to here (inner radius, first angle)
		const largeArcFlag = Math.abs(a2 - a1) > Math.PI ? 1 : 0;
		const pathData = [
			`M ${move_X} ${move_Y}`, // Move
			`A ${r2} ${r2} 0 ${largeArcFlag} 1 ${arc_1_X} ${arc_1_Y}`, // Arc
			`L ${line_X} ${line_Y}`, // Line
			`A ${r1} ${r1} 0 ${largeArcFlag} 0 ${arc_2_X} ${arc_2_Y}`, // Arc
		].join(' ');

		// draw element in the middle of the segment
		const r_mid_segment = r1 + (r2-r1)/2;
		const a_mid_segment = a1 + (a2 - a1) / 2 ;
		const [r_mid_x, r_mid_y] = getCoordinatesForRadius(center, a_mid_segment, r_mid_segment);

		const triangle_r = 0.3*(r2-r1)/2;

		// is element upwards pointing?
		const is_up = element == 0 || element == 1 ? true : false;
		// is element crossed?
		const is_crossed = element == 0 || element == 3 ? true : false;

		// triangle with 60 degrees angle on circle with radius r
		const top_offset = triangle_r * (is_up?1:-1);
		const space_offset = (triangle_r+Math.cos(Math.PI/3)) * (is_up?1:-1);

		const pathData_symbol = [
			`M ${r_mid_x+0} ${r_mid_y-top_offset}`,
			`L ${r_mid_x+space_offset} ${r_mid_y+space_offset}`,
			`L ${r_mid_x-space_offset} ${r_mid_y+space_offset} Z`,
			is_crossed ? `M ${r_mid_x-top_offset*1.4} ${r_mid_y}` : '',
			is_crossed ? `L ${r_mid_x+top_offset*1.4} ${r_mid_y}` : '',
		].join(' ');

		const symbol_fill_color = element == 0 || element == 3 ? Colors.primaryColor : Colors.colorLight;
		const sign = element == 0 || element == 3 ? -1 : 1;
		const shade_variation = PHI*10;

		return (
			<G key={pathData}>
				<Path key={"1"} d={pathData} fill={color} stroke={shadeColor(color, shade_variation*sign)} strokeWidth={stroke_width}  />
				<Path key={"2"} d={pathData_symbol} fill={color} stroke={symbol_fill_color} strokeWidth={stroke_width} />

				{/* <Path originX={200} y={20} scale={0.1} d={d_aries} fill={color} stroke={symbol_fill_color} strokeWidth={0.75} /> */}

				{/* <TextSVG
					x={r_mid_x}
					y={r_mid_y}
					stroke="white"
					textAnchor="middle"
					fontSize="8"
				>{hex}</TextSVG> */}
			</G>
		);
	}
	
	const draw_segment = (c, binary, hex, planet_name) => {
		//```Explicitly returns SVG path command, from angle a1 to a2, at radius r1 to r2, from center c```

		// const binary = IChing.find(x => x.hex === hexagram.hexagram).binary;
		const element_1_b = binary.toString().substring(0,2);
		const element_2_b = binary.toString().substring(2,4);
		const element_3_b = binary.toString().substring(4,6);

		const element_1_seq = Elements.from_binary_to_element_sequence[element_1_b];
		const element_2_seq = Elements.from_binary_to_element_sequence[element_2_b];
		const element_3_seq = Elements.from_binary_to_element_sequence[element_3_b];

		let element_1 = Elements.from_binary_to_number[64][element_1_b];
		let element_2 = Elements.from_binary_to_number[16][element_2_b];
		let element_3 = Elements.from_binary_to_number[4][element_3_b];

		if (element_3 >= 2) { // if odd, we have to reverse the order of counting (air - fire - water - earth)
			element_2 = 4-element_2;
			element_1 = 4-element_1;
		}

		// determine color for each segment
		const color_1 = Elements.colors_basic_symbol[Elements.from_binary[binary.toString().substring(0,2)]];
		const color_2 = Elements.colors_basic_symbol[Elements.from_binary[binary.toString().substring(2,4)]];
		const color_3 = Elements.colors_basic_symbol[Elements.from_binary[binary.toString().substring(4,6)]];

		// calculate ratio of each segment (notice prime number increases!)
		const ratio_3 = -Math.PI/2**1;
		const ratio_2 = -Math.PI/2**3;
		const ratio_1 = -Math.PI/2**5;

		// need to offset everything by 45 degrees to make it nicely (like a D-Pad)
		const offset = Math.PI/4;


		// relative offset for element 3 (inner ring)
		const s3_1 = ratio_3*element_3;
		const s3_2 = ratio_3*(element_3-1);

		// relative offset for element 2 (middle ring), depends if top or bottom of the ring (bottom = earth and water)
		const s2_1 = ratio_2*(element_2 + (element_3 < 2 ? 1 : 0) );
		const s2_2 = ratio_2*(element_2 + (element_3 >= 2 ? -1 : 0) );

		// relative offset for element 2 (middle ring), depends if top or bottom of the ring (bottom = earth and water)
		const s1_1 = ratio_1*(element_1 + (element_3 < 2 ? 1 : 0) );
		const s1_2 = ratio_1*(element_1 + (element_3 >= 2 ? -1 : 0) );

		return (
			<G key={`Slices!${c}${binary}${hex}${planet_name}`}>
				{draw_slice(c,
					s3_1 + offset,
					s3_2 + offset,
					circles_radiuses[0], circles_radiuses[1], color_3, element_3_seq, hex)
				}
				{draw_slice(c,
					s3_2 + s2_1 + offset,
					s3_2 + s2_2 + offset,
					circles_radiuses[2], circles_radiuses[3], color_2, element_2_seq, hex)
				}
				{draw_slice(c,
					s3_2 + s2_2 + s1_1 + offset,
					s3_2 + s2_2 + s1_2 + offset,
					circles_radiuses[4], circles_radiuses[5], color_1, element_1_seq, hex)
				}
			</G>
		)

	}

	const draw_elemental_segments = (c, pieces) => {
		// draw segments by drawing seperation lines

		// correction for human design wheel
		// we dont correct for drawing 12 piecse (the zodiac)
		const segment_orientation = - Math.PI/2.0 + (pieces == 12? 0 : Math.PI/4);
		const segment_arc = (Math.PI*2.0)/pieces;

		// rotation helpers for this segment
		const seg_rad = (iter, extra_rot=0) => (segment_arc * iter) + extra_rot + segment_orientation;
		const x_rot = (iter, r, extra_rot=0) => Math.sin(seg_rad(iter, extra_rot)) * r ;
		const y_rot = (iter, r, extra_rot=0) => Math.cos(seg_rad(iter, extra_rot)) * r ;

		let from_width = pieces == 4 ? circles_radiuses[0] : pieces == 16 ? circles_radiuses[2] : circles_radiuses[4];
		let to_width = pieces == 4 ? circles_radiuses[1] : pieces == 16 ? circles_radiuses[3] : circles_radiuses[5];

		if (pieces == 12){
			from_width = circles_radiuses[5];
			to_width = circles_radiuses[6];
		}

		// const strok_width = 0.5;

		const zodiac_map = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l'];

		const add_zodiac = () => {
			return (
				[...Array(pieces).keys()].map( i => {
					return <TextSVG
						x={c[0] + x_rot(i, to_width, Math.PI/12.0)}
						y={c[1] + y_rot(i, to_width, Math.PI/12.0)}
						fill={outer_circle_color}
						textAnchor="middle"
						dominantBaseline="middle"
						fontSize={font_size}
						fontFamily={Font.processFontFamily('astrodot')}
					>{zodiac_map[i]}</TextSVG>
				})
			)
		}

		return(
			<G>
				{
					[...Array(pieces).keys()].map( i => {
						return <Line
							x1={c[0] + x_rot(i, from_width)}
							y1={c[1] + y_rot(i, from_width)}
							x2={c[0] + x_rot(i, to_width)}
							y2={c[1] + y_rot(i, to_width)}
							stroke={outer_circle_color}
							strokeWidth={stroke_width}
							key={`line_${i}`}
						/>
					})
				}
				{ pieces == 12 && add_zodiac()}
			</G>
		);
	}

	const draw_planet_dails = () => {
		
		if (hexagram !== undefined || hexagram_list!==undefined)
			return;

		if (hexagram_object !== undefined){
			// correction for human design wheel
			// we dont correct for drawing 12 piecse (the zodiac)
			const segment_orientation = - Math.PI/2;
			const ephemeris_correction = 0.015;

			// rotation helpers for this segment
			// const seg_rad = (iter, extra_rot=0) => (segment_arc * iter) + extra_rot + segment_orientation;

			// console.log(time);

			const x_rot = (a, r) => Math.sin(a+segment_orientation+ephemeris_correction) * r ;
			const y_rot = (a, r) => Math.cos(a+segment_orientation+ephemeris_correction) * r ;

			const to_width = circles_radiuses[5]*1.1;
			const symbol_width = circles_radiuses[5]*1.15;
			const text_width = circles_radiuses[5]*1.25;

			return(
				<G>
					{
						_.map(binaries, b => {
							const dashStyle = b.planet_name == 'earth' ? '5,5' : '0,0';
							return <Line
								x1={c[0]}
								y1={c[1]}
								x2={c[0] + x_rot(b.angle_lon, to_width)}
								y2={c[1] + y_rot(b.angle_lon, to_width)}
								stroke={b.color}
								strokeWidth={stroke_w_dials}
								strokeDasharray={dashStyle}
								key={`planet_dail_${b.planet_name}`}
							/>
						})
					}
					{
						_.map(binaries, b => {
							return <TextSVG
								x={c[0] + x_rot(b.angle_lon, symbol_width)}
								y={c[1] + y_rot(b.angle_lon, symbol_width)}
								fill={b.color}
								textAnchor="middle"
								dominantBaseline="middle"
								fontSize={font_size_planet}
								fontFamily={Font.processFontFamily('astrodot')}
								key={`planet_dail_symbol_${b.planet_name}`}
							>{b.planet_symbol}</TextSVG>
						})
					}
					{
						_.map(binaries, b => {
							return <TextSVG
								x={c[0] + x_rot(b.angle_lon, text_width)}
								y={c[1] + y_rot(b.angle_lon, text_width)}
								fill={b.color}
								textAnchor="middle"
								dominantBaseline="middle"
								fontSize={font_size}
								fontFamily={Font.processFontFamily('noticia')}
								key={`planet_dail_line_${b.planet_name}`}
							>{b.hex}.{b.line}</TextSVG>
						})
					}
					
				</G>
			);
		}
	}


	// console.log(hexagram_object.sun.radians);

	console.log('container_width -->', container_width);

	return (
		<Svg height={container_width} width={container_width} viewBox={`0 0 ${container_width} ${container_width}`}>

			{/* Draw dails if available */}
			{draw_planet_dails()}

			{/* Draw Circles */}
			{draw_outer_circles()}

			{/* Draw segments scafolding */}
			{ _.map(binaries, ({planet_name, binary, hex}) => draw_segment(c, binary, hex, planet_name)) }

			{/* Draw segments in ach circle */}
			{draw_elemental_segments(c, 4)}
			{draw_elemental_segments(c, 12)}
			{draw_elemental_segments(c, 16)}
			{draw_elemental_segments(c, 64)}
			
		</Svg>
	);
}

const styles = StyleSheet.create({

});

export default ElementWheelSVG;