import Ephemeris from 'ephemeris';

import IChing from '../constants/i-ching';
import Elements from '../constants/elements';
import IChingWheel from '../constants/i-ching-wheel';

const lon_to_wheel_bin = (planet_position) => {
		// return planet_position / Math.PI// (2.0*Math.pi) // * 64.0 % 64;
		// returns mandala position (base 64) given planet position
		const wheel_offset = planet_position  + 2.0*IChingWheel.line_width - 1.0*IChingWheel.color_width - 2.0*IChingWheel.tone_width;
		return ( wheel_offset / (2.0*Math.PI) * 64.0 )  % 64
}

const map_on_hexagram = (neutron_stream) => {
		// maps mandala position onto a hexagram and line
		const hexagram_bin = Math.floor(neutron_stream); // rounded up downwards

		const hexagram = IChingWheel.wheel[hexagram_bin] // map onto human design (binary line increase) mapping
		const hexagram_fraction = neutron_stream - hexagram_bin;

		// line = 1/6th part of hexagram
		const line = Math.trunc( hexagram_fraction / (1.0/6.0) ) + 1; // count in which 6th this neutrino stream falls in
		const line_fraction = (hexagram_fraction - (line - 1.0)* 1.0/6.0 ) / (1.0/6.0);

		// color = 1/6th part of line
		const color = Math.trunc( line_fraction / (1.0/6.0) ) + 1;
		const color_fraction = (line_fraction - (color - 1.0) * 1.0/6.0) / (1.0/6.0);

		// tone = 1/6th part of color
		const tone = Math.trunc( color_fraction / (1.0/6.0) ) + 1;
		const tone_fraction = (color_fraction - (tone - 1.0) * 1.0/6.0) / (1.0/6.0);

		return {
			hexagram: hexagram,
			line: line, // line gives % in line
			line_fraction: hexagram_fraction,
			color: color,
			color_fraction: line_fraction,
			tone: tone,
			tone_fraction: color_fraction
		}
}

const get_planet_today = (ephemeris, planet) => {

	const eph_correction = 0.015;
	const if_earth_correction = planet == 'earth' ? Math.PI : 0;

	const p = planet == 'earth' ? 'sun' : planet;
	const lon_rad = ephemeris.observed[p].apparentLongitudeDd/180.0*Math.PI + eph_correction + if_earth_correction;

	const hexagram = map_on_hexagram(lon_to_wheel_bin(lon_rad));
	const font = IChing.find(x => x.hex === hexagram.hexagram).hex_font;

	// console.log(hexagram);

	const binary = IChing.find(x => x.hex === hexagram.hexagram).binary;
	const element_1 = Elements.from_binary[binary.toString().substring(0,2)];
	const element_2 = Elements.from_binary[binary.toString().substring(2,4)];
	const element_3 = Elements.from_binary[binary.toString().substring(4,6)];

	return {
			hexagram: hexagram.hexagram,
			hexagram_line: hexagram.line,
			hexagram_color: hexagram.color,
			hexagram_tone: hexagram.tone,
			hexagram_symbol: font,
			binary: binary,

			line_fract: hexagram.line_fraction,
			colo_fract: hexagram.color_fraction,
			tone_fract: hexagram.tone_fraction,

			element_compound: `${element_1} of ${element_2} of ${element_3}`,
			pattern: [element_1, element_2, element_3],
			radians: lon_rad,
			symbol: Elements.planet_symbol[planet],
			planet: planet,
			q_color: Elements.planet_q_colors[planet] // kabalah color
	};
}

const planets_from_names = (eph, names) => {
	// eph = ephemeris returned from ephemeris package
	// names = array of planet names to include
	const planets = {}
	names.forEach(p_name => {
		const planet = get_planet_today(eph, p_name);
		planets[p_name] = planet;
	});

	return planets;
}

const incarnation_cross_object = (eph_0, eph_88, names) => {
	// eph = ephemeris returned from ephemeris package
	// names = array of planet names to include
	const planets = {}
	names.forEach(p_name => {
		const planet_0 = get_planet_today(eph_0, p_name); // one from 0 degress (personality)
		const planet_88 = get_planet_today(eph_88, p_name); // one for -88 degrees (design)
		planets[`p_${p_name}`] = planet_0; // personality planet
		planets[`d_${p_name}`] = planet_88; // design planet
	});
	return planets;
}

export const getEphemerisCross = (nowDate, location) => {
	// Retrieve incarnation cross ephemeris

	const coords = location != -1 ? location.coords: [50, 5];
	const [lon, lat] = coords;
	const height = 150;

	// 'guess' date for sun being 88 degrees back on thee wheel (roughly -88 days)
	let date_88 = new Date(nowDate.getTime());
	date_88.setDate(date_88.getDate() - 88);

	console.log("now", nowDate);
	console.log("guess", date_88);

	// calculate now-ephemeris, and that of 88 days back
	const eph_now = Ephemeris.getAllPlanets(nowDate, lon, lat, height);
	let eph_88_est = Ephemeris.getPlanet("sun", date_88, lon, lat, height);

	// retrieve apparent longitudes
	let eph_deg_now = eph_now.observed["sun"].apparentLongitudeDd;
	let eph_deg_est = eph_88_est.observed["sun"].apparentLongitudeDd;

	// adjust sun position at birth with 360 if we enter into the negative
	if (eph_deg_now - eph_deg_est < 0)
		eph_deg_now+=360

	// Optimize date calculation (using 50% approach towards 88 degrees in difference)
	const max_tries = 20;
	let tries = 0;
	let diff = eph_deg_now - eph_deg_est;

	while (Math.abs(88-Math.abs(diff)) > 0.00001 && tries < max_tries){

		// Adjust seconds in the 88-degree date
		// Seconds in day => 24 hours div 2 = 12 hours

		const direction_of_adjustement = 88-diff > 0 ? -1 : +1;

		date_88.setSeconds(date_88.getSeconds() + direction_of_adjustement * ((88-diff)*12*60*60) );

		// Recalculate sun
		eph_88_est = Ephemeris.getPlanet("sun", date_88, lon, lat, height);
		eph_deg_est = eph_88_est.observed["sun"].apparentLongitudeDd;

		// Calculate diff (diff should be 88 degrees)
		diff = eph_deg_now - eph_deg_est;

		// Prevent infinite loops
		tries += 1;
	}

	// final 88 deg calculation
	const eph_88 = Ephemeris.getAllPlanets(date_88, lon, lat, height);

	console.log(nowDate);
	console.log(date_88);

	// extraction of planets of interest
	const planet_names = ['sun', 'moon', 'earth', 'mars', 'venus', 'mercury', 'jupiter', 'saturn']
	return incarnation_cross_object(eph_now, eph_88, planet_names);
}

export const getEphemeris = (nowDate, showAdditionalPlanets, coordinates) => {
	const coords = coordinates != -1 ? coordinates.coords: null;  

	// calculate ephemeris  
	// parameters: ephemeris.getAllPlanets(dateObj, longitude, latitude, height);
	let result = null;
	if (coords == null ) {
		result = Ephemeris.getAllPlanets(nowDate, 0, 0, 0);
	} else {
		const { longitude, latitude } = coords;
		// console.log( longitude, latitude);
		result = Ephemeris.getAllPlanets(nowDate, longitude, latitude, 0);
	}
	const planet_names = ['sun', 'moon', 'earth']
	const additionalPlanets = ['mars', 'venus', 'mercury', 'jupiter', 'saturn'] ; //, 'jupiter'];

	const planets = planets_from_names(result, planet_names.concat(showAdditionalPlanets ? additionalPlanets : []) );

	// console.log(planets);

	const planet_demo_base = {
		"earth": {
			"colo_fract": 0.5476426632423711,
			"element_compound": "🜃 of 🜃 of 🜁",
			"hexagram": "䷒",
			"hexagram_color": "4",
			"hexagram_line": "2",
			"hexagram_num": "19",
			"hexagram_tone": "2",
			"line_fract": 0.2579404438737285,
			"pattern": [
				"🜃",
				"🜃",
				"🜁",
			],
			"q_color": "#FF9B41",
			"radians": 5.410391206784714,
			"symbol": "⊗",
			"tone_fract": 0.2858559794542266,
		},
		"jupiter": {
			"colo_fract": 0.2996592936942475,
			"element_compound": "🜃 of 🜄 of 🜁",
			"hexagram": "䷵",
			"hexagram_color": "2",
			"hexagram_line": "6",
			"hexagram_num": "54",
			"hexagram_tone": "5",
			"line_fract": 0.8832765489490413,
			"pattern": [
				"🜃",
				"🜄",
				"🜁",
			],
			"q_color": "#2968c7",
			"radians": 5.079084353640023,
			"symbol": "♃",
			"tone_fract": 0.797955762165485,
		},
		"mars": {
			"colo_fract": 0.19519812856642904,
			"element_compound": "🜃 of 🜄 of 🜂",
			"hexagram": "䷲",
			"hexagram_color": "2",
			"hexagram_line": "4",
			"hexagram_num": "51",
			"hexagram_tone": "2",
			"line_fract": 0.5325330214277382,
			"pattern": [
				"🜃",
				"🜄",
				"🜂",
			],
			"q_color": "#ef1a0c",
			"radians": 0.332261207962987,
			"symbol": "♂",
			"tone_fract": 0.1711887713985743,
		},
		"mercury": {
			"colo_fract": 0.7424780768902225,
			"element_compound": "🜃 of 🜁 of 🜃",
			"hexagram": "䷽",
			"hexagram_color": "5",
			"hexagram_line": "2",
			"hexagram_num": "62",
			"hexagram_tone": "3",
			"line_fract": 0.29041301281503706,
			"pattern": [
				"🜃",
				"🜁",
				"🜃",
			],
			"q_color": "#fa7b00",
			"radians": 1.977462228921791,
			"symbol": "☿",
			"tone_fract": 0.4548684613413352,
		},
		"moon": {
			"colo_fract": 0.7955411386284653,
			"element_compound": "🜂 of 🜄 of 🜁",
			"hexagram": "䷹",
			"hexagram_color": "5",
			"hexagram_line": "2",
			"hexagram_num": "58",
			"hexagram_tone": "5",
			"line_fract": 0.29925685643807753,
			"pattern": [
				"🜂",
				"🜄",
				"🜁",
			],
			"q_color": "#848BD7",
			"radians": 4.825398813554904,
			"symbol": "☾",
			"tone_fract": 0.7732468317707921,
		},
		"sun": {
			"colo_fract": 0.5476426632423285,
			"element_compound": "🜁 of 🜁 of 🜃",
			"hexagram": "䷠",
			"hexagram_color": "4",
			"hexagram_line": "2",
			"hexagram_num": "33",
			"hexagram_tone": "2",
			"line_fract": 0.2579404438737214,
			"pattern": [
				"🜁",
				"🜁",
				"🜃",
			],
			"q_color": "#f9e50a",
			"radians": 2.268798553194921,
			"symbol": "☉",
			"tone_fract": 0.2858559794539708,
		},
		"venus": {
			"colo_fract": 0.4913609800707946,
			"element_compound": "🜁 of 🜄 of 🜃",
			"hexagram": "䷋",
			"hexagram_color": "3",
			"hexagram_line": "2",
			"hexagram_num": "12",
			"hexagram_tone": "6",
			"line_fract": 0.24856016334513242,
			"pattern": [
				"🜁",
				"🜄",
				"🜃",
			],
			"q_color": "#15a124",
			"radians": 1.482479482910059,
			"symbol": "♀",
			"tone_fract": 0.9481658804247677,
		}
	};

	return {
		...planets,
		date: new Date()
	};
}
