import React from 'react';
import { View, Text, StyleSheet, Platform, Dimensions, TouchableOpacity } from 'react-native';

import LineWheel from './LineWheel';
import LineWheelSVG from './LineWheelSVG';

import Elements from '../constants/elements';

const PLANETINFO_CONTENT_HEIGHT = 75;
const PLANETINFO_CONTENT_WIDTH = 100;
const PLANET_CONTENT_PER_SCREEN = Dimensions.get('window').width < 500 ? 2 : 3;

// calculate margin for this component, if we'd to allow `PLANET_CONTENT_PER_SCREEN` to be displayed per screen


const PlanetInfoWidget = props => {

	const {hexagram, hexagram_line, hexagram_color, hexagram_tone, hexagram_symbol, element_compound, planet, pattern} = props.planet;

	const { vertical } = props;

	const get_element_style = (element) => {
		return {
			// color: Elements.colors[Elements.from_symbol[element]],
			color: "#ccc",
			fontSize: 15
		};
	}

	

	const [heading, setHeading] = React.useState(0);

	const MARGIN = ((Dimensions.get('window').width / PLANET_CONTENT_PER_SCREEN) -  (PLANETINFO_CONTENT_WIDTH)) / 2;

	const planet_symbol = (planet) => {
		return <Text style={{...styles.hexagram_num, fontSize:25, fontFamily:'astrodot'}}>{Elements.planet_symbol_font[planet]}</Text>
	}

	return(
			<View style={{
				...styles.container,
				marginHorizontal: vertical == false ? MARGIN : 0,
				marginVertical: vertical == false ? 0 : 20,
				}}
			>
				<TouchableOpacity onPress={(event) => props.onPress(hexagram)}>
					{/* <Compass setHeading={setHeading}></Compass> */}
					{/* <Text>{heading}</Text> */}
					<Text style={styles.hexagram_num}>{planet_symbol(planet)}  {hexagram}<Text style={styles.line}>.{hexagram_line}</Text></Text>
					{/* <View style={{justifyContent: 'center', alignItems: 'center', width:'100%'}}>
						<View style={styles.hexagram_row}>
							<Text style={styles.hexagram}>{hexagram_symbol}</Text>
							<LineWheelSVG style={styles.linewheel} ephemeris={props.planet} width={45} />
							<View style={{flexDirection: 'col', paddingVertical: 2}}>
								<Text style={get_element_style(pattern[0])}>{pattern[0]}</Text>
								<Text style={get_element_style(pattern[1])}>{pattern[1]}</Text>
								<Text style={get_element_style(pattern[2])}>{pattern[2]}</Text>
							</View>
						</View>
					</View> */}
					{/* <Text style={styles.element_compound}>{element_compound}</Text> */}
				</TouchableOpacity>
			</View>
		
	)
};



const styles = StyleSheet.create({

	container: {
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		alignContent: 'center',
		// paddingTop: 4,
		// marginTop: '10%',
		// marginBottom: 5,
		
		height: PLANETINFO_CONTENT_HEIGHT,
		// width: PLANETINFO_CONTENT_WIDTH
		// overflow: 'visible'

		// flexDirection: 'column',
	},	

	hexagram_row: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		// height: 45,
		width: PLANETINFO_CONTENT_WIDTH
	},

	linewheel: {
		height: 30,
		width: 30,
		// marginTop: -20
	},

	hexagram_num: {
		flexDirection: 'row',
		...Platform.select({
			ios: {
				fontSize: Dimensions.get('window').width / 20 ,// < 400 ? 12 : 20,
			},
			android: {
				fontSize: Dimensions.get('window').width / 25 ,// < 400 ? 12 : 20,
			},
			web: {
				fontSize: 25
			}
		}),
		textAlign: 'center',
		alignItems: 'center',
		alignContent: 'center',
		justifyContent: 'center',
		fontFamily: 'noticia',
		// fontSize: 16,
		color: "#ccc",
		// padding: 1,
		marginTop: 0
	},
	hexagram: {
		...Platform.select({
			ios: {
				fontSize: Dimensions.get('window').width / 7,
				marginBottom: 0,
				marginTop: 0,
			},
			android: {
				fontSize: Dimensions.get('window').width / 11,
				marginTop: -10,
				marginBottom: -10,
			},
		}),
		fontSize: 25,
		color: "#ccc",
	},
	line: {
		fontSize: 10,
		// lineHeight: 12 * 0.6, 
		// textAlignVertical: 'center',
		color: "#ccc",
		padding: 3
	},
	element_compound: {
		...Platform.select({
			ios: {
				fontSize: Dimensions.get('window').width / 20 ,// < 400 ? 12 : 20,
			},
			android: {
				fontSize: Dimensions.get('window').width / 25 ,// < 400 ? 12 : 20,
			},
			web: {
				fontSize: 25
			}
		}),
		
		marginVertical: 5,
		fontFamily: 'play',
		color: "#ccc",
		textAlign: 'center',
	},
});

export default PlanetInfoWidget;