import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';

// import TouchableDebounce from '../Wrappers/TouchableDebounce';

import Colors from '../constants/Colors';

const MainButton = props => {
	return (
		<TouchableOpacity onPress={props.onPress}>
			<View style={{...styles.button, ...props.style}}>
				<Text style={{...styles.buttonText, color: props.text_color}}>{props.children}</Text>
			</View>
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	button: {
		alignItems: 'center',
		justifyContent: 'center',

		backgroundColor: Colors.primaryColor,
		// paddingVertical: 2,
		// paddingHorizontal: 2,
		borderRadius: 17
	},

	buttonText: {
		color: 'white',
		fontFamily: 'noticia',
		fontSize: 20
	}
});

export default MainButton;