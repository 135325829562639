import React from 'react';
import { View, StyleSheet } from 'react-native';
import Svg, { Circle, Path, Line, Defs, Marker } from 'react-native-svg';
import _ from 'lodash';
import Colors from '../../constants/Colors';

const TreeOfLife = (props) => {

	// Component Usage
	// <TreeOfLife height={height} radius_sephira={radius_sephira} selected={['kether', 'earth']}/>
	//
	// Specify in `selected` which sephira you want to select in your UI
	// Options:
	// Main Sephirat: 

	const { height, radius_sephira, selected, pathways } = props;

	// Third of circle radius
	const r_3 = height / 3 / 2;

	const hexagram_angle = 2*Math.PI/6.0;
	const side_pillar_x_offset = Math.sin(hexagram_angle)*r_3;
	const side_pillar_y_offset = r_3 - Math.cos(hexagram_angle)*r_3;

	const sephirot = {
		// pillar of future
		3: { // binah
			cx: height/2 - side_pillar_x_offset,
			cy: r_3 + side_pillar_y_offset,
			fill_color: 'black'
		},
		5: { // geburah
			cx: height/2 - side_pillar_x_offset,
			cy: r_3*2 + side_pillar_y_offset,
			fill_color: '#FF0000'
		},
		8: { // hod
			cx: height/2 - side_pillar_x_offset,
			cy: r_3*3 + side_pillar_y_offset,
			fill_color: '#FF9900'
		},

		// pillar of now
		1: { // kether
			cx: height/2,
			cy: r_3,
			fill_color: 'white'
		},
		6: { // tipareth
			cx: height/2,
			cy: height/2,
			fill_color: '#FFff00'
		},
		9: { // yesod
			cx: height/2,
			cy: height-r_3*2,
			fill_color: '#CC00CC'
		},
		10: { // malkuth
			cx: height/2,
			cy: height-r_3,
			fill_color: '#CC6633'
		},

		// pillar of past
		2: { // chokmah
			cx: height/2 + side_pillar_x_offset,
			cy: r_3 + side_pillar_y_offset,
			fill_color: '#999999'
		},
		4: { // chesed
			cx: height/2 + side_pillar_x_offset,
			cy: r_3*2 + side_pillar_y_offset,
			fill_color: '#0000FF'
		},
		7: { // netzach
			cx: height/2 + side_pillar_x_offset,
			cy: r_3*3 + side_pillar_y_offset,
			fill_color: '#00CC00'
		},
	};

	const malkuth_elements = {
		air: {
			start_angle: -Math.PI/4 - Math.PI/2, 
			end_angle: Math.PI/4 - Math.PI/2,
			color: '#FFFF66',
		},
		fire: {
			start_angle: -Math.PI/4 + Math.PI, 
			end_angle: Math.PI/4 + Math.PI,
			color: '#CC6633',
		},
		water: {
			start_angle: -Math.PI/4, 
			end_angle: Math.PI/4,
			color: '#669900',
		},
		earth: {
			start_angle: -Math.PI/4 + Math.PI/2,
			end_angle: Math.PI/4 + Math.PI/2,
			color: '#000000',
		},
	}

	const getCoordinatesForRadius = (center, angle, radius) => {
		const x = center[0] + Math.cos(angle)*radius;
		const y = center[1] + Math.sin(angle)*radius;
		return [x, y];
	}

	const draw_slice = (center, angle_start, angle_stop, radius, color) => {
		// given angle_start & angle_stop, draw pie chart around center, with radius and color specified
		const [startX, startY] = getCoordinatesForRadius(center, angle_start, radius);
		const [endX, endY]     = getCoordinatesForRadius(center, angle_stop, radius);
		const largeArcFlag = Math.abs(angle_stop - angle_start) > Math.PI ? 1 : 0;
		const pathData = [
			`M ${startX} ${startY}`, // Move
			`A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
			`L ${center[0]} ${center[1]}`, // Line
		].join(' ');

		return <Path d={pathData} fill={color} key={pathData} />;
	}

	const draw_pathways = () => {
		const from = Array.isArray(pathways[0]) ? pathways[0] : [pathways[0]];
		const to = Array.isArray(pathways[1]) ? pathways[1] : [pathways[1]];

		let pathways_coord = [];
		let elements = ['air', 'fire', 'water', 'earth'];

		for (let i=0; i<from.length; i++)
			for (let j=0; j<to.length; j++){
				let seph_from = elements.includes(from[i]) ? 10 : parseInt(from[i]);
				let seph_to = elements.includes(to[j]) ? 10 : parseInt(to[j]);

				pathways_coord.push({
					x1: sephirot[seph_from].cx,
					y1: sephirot[seph_from].cy,
					x2: sephirot[seph_to].cx,
					y2: sephirot[seph_to].cy,
				});
			}

		return _.map(pathways_coord, (path, ii) => {
			// const dashStyle = b.planet_name == 'earth' ? '5,5' : '0,0';
			return <Line
				x1={path.x1}
				y1={path.y1}
				x2={path.x2}
				y2={path.y2}
				stroke={Colors.accentColor}
				strokeWidth={0.5}
				key={`pathway_${ii}`}
				markerEnd="url(#triangle)"
			/>
		});
	}

	const size_arrow=5;

	return (
		<Svg height="100%" width="100%" viewBox={`0 0 ${height} ${height}`}>

			<Defs>
				<Marker
					id="triangle"
					viewBox={`0 0 ${size_arrow} ${size_arrow}`}
					refX={size_arrow+radius_sephira*2}
					refY={size_arrow/2}
					markerUnits="strokeWidth"
					markerWidth={size_arrow}
					markerHeight={size_arrow*0.8}
					orient="auto"
				>
					<Path
						fill={Colors.accentColor}
						d={`M 0 0 L ${size_arrow} ${size_arrow/2} L 0 ${size_arrow} z`}
					/>
				</Marker>
			</Defs>

			{/* Draw Pathways */}
			{pathways !==undefined && draw_pathways()}

			{/* Draw Main Sephirot */}
			{_.map(sephirot, (s, name) => {
				const selected_element = selected.some(e => e == name);
				const stroke = selected_element ? null: "white";
				const fill = selected_element // check if selected
					? s.fill_color 
					: ( selected.some(e => e == 'earth') && name == 10 )
					? '#888'
					: Colors.primaryColor;

				// if ( selected.some(e => e == 'air') && name == 10 )

				return (
					<Circle
						cx={s.cx}
						cy={s.cy}
						r={radius_sephira}
						stroke={stroke}
						strokeWidth="0.5"
						fill={fill}
						key={name}
					/>
				)
			})}

			{/* Draw Malkuth Slices */}
			{_.map(malkuth_elements, (m, name) => {
				const selected_element = selected.some(e => e == name);
				if (!selected_element) return;
				return (
					draw_slice([sephirot[10].cx, sephirot[10].cy], m.start_angle, m.end_angle, radius_sephira, m.color)
				)
			})}

		</Svg>
	)
}

const styles = StyleSheet.create({
	screen: {

	}
});

export default TreeOfLife