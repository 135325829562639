import React from 'react';
import { View, Text, StyleSheet, Image, ScrollView} from 'react-native';

import FocusAwareStatusBar from './FocusAwareStatusBar';
import Colors from '../constants/Colors';
import splash_image from '../assets/splash.png'; 

const AboutScreen = (props) => {
	// StatusBar.setBarStyle('dark-content');
	return (
		<View style={styles.screen}>
			<FocusAwareStatusBar barStyle="dark-content"/>
			<ScrollView style={{width:'100%'}}>
				<Image resizeMode="contain" source={splash_image} style={{width:'100%', height:500}}/>

				<View style={{justifyContent: 'center', alignItems: 'center'}}>
					<View style={{marginHorizontal:'20%'}}>
						<Text style={styles.text_normal}>Welcome to Gnomon - Time Companion!</Text>
						<Text style={styles.text_normal}>An app to use next to your contemplative, spiritual, philosophical, or magical practise, to note the time, look-up I Ching & Gene Key information, and explore intricate patterns within the matrix of time and meaning.</Text>
						<Text style={styles.text_normal}>The Time tab shows you the time, date, weather and an apt representation of the sky, with indicators where the planets are at. The planetary positions are projected geocentrically, and approximated using Moshier's Algorithm</Text>
						<Text style={styles.text_normal}>The Lookup tab gives one the opportunity to explore the 64 Hexagrams and Gene Keys in a novel way, ie by using elemental compounds. Traditionally Hexagrams are formed by two trigrams. In my own work I've been exploring the meaning and design behind hexagrams created from three digrams (or four elements!). The kabbalistic asignment comes from the most-significant-bit interpretation of the 4x4 Kabbalah Meaning Matrix attribution. This tool is partly created to explore this peculiar innovation.</Text>
						<Text style={styles.text_normal}>The Profile tab allows one to study compositions and configurations on the Hexagram Wheel, including analysis of past dates and particular hexagram groupings.</Text>
						<Text style={styles.text_normal}>The creator uses Gnomon to compliment his daily journal practise for recording spiritual, magical experience, and non-linear experiences.</Text>
						<Text style={styles.text_normal}>Bookmark this tool, and share the word!</Text>
					</View>
				</View>
			</ScrollView>
		
		</View>
	);
}

const styles = StyleSheet.create({
	screen: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#2F2F2F',
		width:'100%'
	},

	text_normal: {
		fontSize: 16,
		color: Colors.accentColor,
		fontFamily: 'Noticia',
		textAlign: 'center',
		marginBottom: 25,
	}
});

export default AboutScreen;