import React, {useState, useEffect}  from 'react';
import { View, Text, StyleSheet, Dimensions, Animated, Platform, TouchableOpacity } from 'react-native';

import Colors from '../constants/Colors';
import Elements from '../constants/elements';
import Genekeys from '../genekeys-dataset/genekeys';
import TreeOfLife from '../components/SVG/TreeOfLife';

import DefaultText from '../components/DefaultText'
// import { TouchableOpacity } from 'react-native-gesture-handler';


const HexagramShortInfo = ({item, onClick, numberColumns}) => {

	const {hex, hex_font, binary, english } = item;

	const {
		keyword, dilemma,
		repressive, reactive,
		shadow, gift, siddhi,
		codon, AA
	} = Genekeys.find(x => x.hex === parseInt(hex));

	// const binary = IChing.find(x => x.hex === hexagram.hexagram).binary;
	const element_1 = Elements.from_binary[binary.toString().substring(0,2)];
	const element_2 = Elements.from_binary[binary.toString().substring(2,4)];
	const element_3 = Elements.from_binary[binary.toString().substring(4,6)];

	const get_element_style = (element) => {
		return {
			color: Elements.colors[Elements.from_symbol[element]],
			fontSize: 30
		};
	};
	
	const selected_saphira = [
		Elements.bielement_to_treeoflife[element_3][element_2],
		Elements.bielement_to_treeoflife[element_2][element_1]

		// Dimonicano Sancto
		// Elements.bielement_to_treeoflife[element_2][element_1],
	];

	return(
		<TouchableOpacity onPress={() => onClick(hex, english)}>
			<View style={styles.hexagram_item}>

				<View style={{justifyContent: 'center', alignItems: 'center'}}>
					<Text style={styles.header_text}>{english}</Text>
					<Text style={styles.header_text}>{keyword}</Text>
				</View>

				<View style={styles.visual_information}>
					<View style={{justifyContent: 'center', alignItems: 'center', margin: 0,}}>
						<Text style={{color:Colors.accentColor, marginBottom: 3}}>{item.hex}</Text>
						<Text style={{color:Colors.accentColor, fontSize: 50}}>{item.hex_font}</Text>
					</View>
					
					<View style={{width: 125, justifyContent: 'center'}}>
						<TreeOfLife height={55} radius_sephira={3} selected={selected_saphira.flat()}  pathways={selected_saphira}/>
					</View>

					<View style={{flexDirection: 'column', width: 40, justifyContent: 'center',}}>
						<Text style={get_element_style(element_1)}>{element_1}</Text>
						<Text style={get_element_style(element_2)}>{element_2}</Text>
						<Text style={get_element_style(element_3)}>{element_3}</Text>
					</View>
				</View>
				
				<View style={{justifyContent: 'center', alignItems: 'center'}}>
					<Text style={styles.smallprint_info}>{codon}</Text>
					<Text style={styles.genekey_header}><Text style={{color:'red'}}>{shadow}</Text> <Text style={{color:'lightgreen'}}>{gift}</Text> <Text style={{color:'lightblue'}}>{siddhi}</Text></Text>
				</View>
				

			</View>
		</TouchableOpacity>
	);
}

const styles = StyleSheet.create({
	hexagram_item: {
		flex:1,
		flexDirection: 'column',
		width: 300,
		height: 200,
		alignItems: 'center',
		justifyContent: 'space-evenly',
		backgroundColor: Colors.primaryColor,
		marginVertical: 5,
		padding: 5,
		borderRadius: 15,
		borderColor: Colors.primaryWeakColor,
		borderWidth: StyleSheet.hairlineWidth
	},
	header_text: {
		color: Colors.accentColor,
		fontSize: 15,
		fontFamily: 'prompt-regular'
	},

	visual_information: {
		flexDirection: 'row',
		width: '100%',
		height: 100,
		alignItems: 'center',
		justifyContent: 'space-around',

		backgroundColor: Colors.primaryColor,
		marginVertical: 10
	},
	smallprint_info: {
		color: Colors.accentColor,
		fontSize: 10,
		fontFamily: 'prompt-light'
	},
	genekey_header: {
		color: Colors.accentColor,
		fontSize: 15,
		fontFamily: 'prompt-regular',
		// justifyContent: 'space-around'
	},
});

export default HexagramShortInfo;

