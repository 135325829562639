import React from 'react';
import Canvas from 'react-native-canvas';

export default class PureCanvas extends React.Component {
	shouldComponentUpdate() {
	  return false;
	}
  
	// componentDidMount() {
	// 	canvas.width = styles.container.width;
    // 	canvas.height = styles.container.height;
	// }

	render() {
	  return (
		<Canvas
		  ref={node => {
			  // set width and height & return reference to this purecannvas
			  if (node){
				  node.width = this.props.width;
				  node.height = this.props.height;
				  return this.props.contextRef(node.getContext('2d'));
			  } else
			  	return null;
		  }
			
		  }
		/>
	  );
	}
  }