import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Dimensions} from 'react-native';

import FocusAwareStatusBar from '../FocusAwareStatusBar';

// Helpers
import { getEphemeris, getEphemerisCross } from '../../helpers/astrology';
import HolographicChart from '../../components/SVG/HolographicChart';
import Colors from '../../constants/Colors';

import ProfileListScreen from './ProfileListScreen';

const ImpactScreen = (props) => {

	const [profileData, setProfileData] = useState("");

	const MIDWIDTH_SPLITSCREEN = 400;

	useEffect(() => {
		const now = new Date("1992-12-09T00:35:00");
		const eph = getEphemerisCross(now, -1);
		setProfileData(eph);
		console.log(eph);
	}, []);

	const handleSelectProfile = () => {
		
	}

	// when on bigger screens -> split screen
	if (Dimensions.get('window').width >= MIDWIDTH_SPLITSCREEN)
		return (
			<View style={styles.screen_split}>
				<FocusAwareStatusBar barStyle="dark-content"/>
				
				{/* <View stlye={{height:'100%'}}> */}
				<ProfileListScreen/>
				{/* </View> */}

				<View style={{backgroundColor: Colors.primaryColor, height:'100%', flex:1, alignItems: 'center', justifyContent: 'center', borderTopLeftRadius: 30}}>
					<View style={{width:500, height:500}}>
						<HolographicChart height={500} radius_spheres={20} profile={profileData} />
					</View>
				</View>
			</View>
		);

	// when on smaller screens -> no split screen
	else return (
		<View style={styles.screen}>
			<FocusAwareStatusBar barStyle="dark-content"/>

			<View style={{height:500, width:500}}>
				<HolographicChart height={500} radius_spheres={20} profile={profileData} />
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	screen_split: {
		flex: 1,
		height:'100%',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: Colors.accentColor,
	},

	screen: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: Colors.primaryColor,
	}
});

export default ImpactScreen;