import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Colors from '../constants/Colors.js';

const DefaultText = (props) => {
	// const { style } = props;

	const ss = {
		color: Colors.accentColor,
		fontFamily: 'noticia',
		width: '80%',
		textAlign: 'center',
		fontSize: 13,

		...props.style
	}

	return (
		// <Text style={{ ...styles.default_style, ...style }}>{props.children}</Text>
		<Text style={ss}>{props.children}</Text>
	);
}

export default DefaultText;