import { StatusBar } from 'expo-status-bar';
import React, { useState } from 'react';
import { StyleSheet } from 'react-native';

import { enableScreens } from 'react-native-screens';
import * as Font from 'expo-font';
import AppLoading from 'expo-app-loading';

import GnomonNavigator from './navigation/GnomonNavigator';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';

// make screens perform at native level
enableScreens();

import { injectWebCss } from './helpers/disableBlueGlow';
import { disableRubberband } from './helpers/disableRubberband';

// fetch fonts
const fetchFonts = () => {
	return Font.loadAsync({ // a promise
		'astroganza': require('./assets/fonts/Astroganza.ttf'),
		'astrodot': require('./assets/fonts/Astrodotbasic.ttf'),

		'open-sans': require('./assets/fonts/OpenSans-Regular.ttf'),
		'open-sans-bold': require('./assets/fonts/OpenSans-Bold.ttf'),

		'noticia': require('./assets/fonts/NoticiaText-Regular.ttf'),
		'noticia-italic': require('./assets/fonts/NoticiaText-Italic.ttf'),
		'noticia-bold': require('./assets/fonts/NoticiaText-Bold.ttf'),

		'garamond': require('./assets/fonts/EBGaramond-Regular.ttf'),
		'garamond-italic': require('./assets/fonts/EBGaramond-Italic.ttf'),
		'garamond-bold': require('./assets/fonts/EBGaramond-ExtraBold.ttf'),

		'timea': require('./assets/fonts/Timea.ttf'),
		'play': require('./assets/fonts/Play-Regular.ttf'),
		'play-bold': require('./assets/fonts/Play-Bold.ttf'),

		'prompt-bold': require('./assets/fonts/Prompt-Bold.ttf'),
		'prompt-light': require('./assets/fonts/Prompt-Light.ttf'),
		'prompt-regular': require('./assets/fonts/Prompt-Regular.ttf'),
		'prompt-thin': require('./assets/fonts/Prompt-Thin.ttf'),
	});
}

export default function App() {

	// load fonts / assets
	const [assetsLoaded, setAssetsLoaded] = useState(false);

	if (!assetsLoaded) {
		return ( <AppLoading
			startAsync={fetchFonts} // AppLoading prolongs the loading till "async function" is done
			onFinish={() => setAssetsLoaded(true)}
			onError={err => console.log(err)}
		/>
		);
	}

	injectWebCss();
	disableRubberband();

	return (
		// <GnomonNavigator/>
		// <View style={styles.container}>
		<SafeAreaProvider>
			
			{/* <View style={{justifyContent: 'center', alignItems: 'center'}}>
				<View style={{width:'80vw', height:'80vh'}}> */}
					<GnomonNavigator/>
				{/* </View>
			</View> */}
			{/* <Text>Open up App.js to start working on your app!</Text>
			<StatusBar style="auto" /> */}
			
		</SafeAreaProvider>
		
	);
}

const styles = StyleSheet.create({
	container: {
		// flex: 1,
		height:'100%',
		// margin: '5%',
		// width: '80%',
		// height: '100%',
		backgroundColor: '#fff',
		// alignItems: 'center',
		// justifyContent: 'center',
	},
});
