import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity} from 'react-native';
import FocusAwareStatusBar from '../FocusAwareStatusBar';

// Helpers
import { getEphemeris, getEphemerisCross } from '../../helpers/astrology';

import _ from 'lodash';
import Colors from '../../constants/Colors';
import { Ionicons } from '@expo/vector-icons';

const ProfileListScreen = (props) => {

	const [profiles, setProfiles] = useState({1:{key:1, name:'Adam Blazejczak'}, 2:{key:2, name:'Tine Driessen'}});
	const [selectedProfile, setSelectedProfile] = useState(1);

	const handleSelectProfile = key => {
		setSelectedProfile(key);
	}

	const handleAddProfile = key => {
		
	}

	const renderProfileItem = profile => {

		const {key, name, note, location, tag } = profile;

		const extra_margin_selected = key == selectedProfile ? {
			marginRight: 0,
			backgroundColor: Colors.primaryColor,
			borderTopRightRadius:0,
			borderBottomRightRadius:0,
		} : {};

		return (
			<TouchableOpacity onPress={()=>handleSelectProfile(key)}>
				<View style={{height: 100, borderRadius:20, backgroundColor: Colors.primaryLightColor, padding:8, marginBottom:15, marginHorizontal:5, ...extra_margin_selected}}>
					<Text style={{fontSize:18, fontFamily:'noticia-bold', color:Colors.accentColor, margin:2}}>{name}</Text>
					<Text style={{fontSize:15, fontFamily:'noticia', color:Colors.accentColor, margin:2}}>View testing, the power of the lord</Text>
				</View>
			</TouchableOpacity>
		);
	}

	return (
		<View style={styles.screen}>
			<FocusAwareStatusBar barStyle="light-content"/>

			<Text style={{margin:10, fontSize:20, fontFamily:'noticia-bold', color:Colors.primaryColor}}>Profiles</Text>

			<View style={{marginVertical:20, flex:1}}>
				<ScrollView>
					{_.map(profiles, (profile) =>{
						return renderProfileItem(profile);
					})}
				</ScrollView>
			</View>

			<View style={{position: 'absolute', width:45, height:45, bottom:10, right:10, zIndex:3}}>
				<TouchableOpacity>
					<View style={{width:45, height:45, borderRadius:45/2, backgroundColor:Colors.primaryColor}}>
						<View style={{flex:1, justifyContent:'center', alignItems: 'center'}}>
							<Ionicons name="add" size={35} color={Colors.accentColor}/>
						</View>
					</View>
				</TouchableOpacity>
			</View>
			
		</View>
	);
}

const styles = StyleSheet.create({
	screen: {
		width: 250,
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: Colors.accentColor
	}
});

export default ProfileListScreen;